import { Helmet } from 'react-helmet-async';
import { COMPANY_NAME } from 'src/_mock/constant';
// sections
import { ListView } from 'src/sections/automobile-reports/webreport';


export default function WebReport() {
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} : Web Report</title>
      </Helmet>

      <ListView />
    </>
  );
}
