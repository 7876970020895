import { Helmet } from 'react-helmet-async';

// routes
import { useParams } from 'src/routes/hooks';
// sections
import { InvoiceListView } from 'src/sections/automobile-transaction/commoninvoice/view';

// ----------------------------------------------------------------------

export default function InvoiceListPage() {

  const params = useParams();

  const { id, t_type, pcategory } = params;
  console.log(params)
  return (
    <>
      <Helmet>
        <title> Dashboard: Invoice List</title>
      </Helmet>

      <InvoiceListView params={params} />
    </>
  );
}
