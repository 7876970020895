import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { AuthContext } from 'src/auth/context/jwt';

// ----------------------------------------------------------------------

import ModuleListPage from 'src/pages/dashboard/module/list';
import BankOfficeDashboard from 'src/pages/dashboard/automobile-dashboard/backoffice/backofficedashboard';
import DepartmentListPage from 'src/pages/dashboard/department/list';
import RolesListPage from 'src/pages/dashboard/roles/list';
import EnquiryListPage from 'src/pages/dashboard/enquiry/list';
import HondaCategory from 'src/pages/dashboard/hondacetegory/list';
import CompanyListPage from 'src/pages/dashboard/company/list';
import CompanybranchListPage from 'src/pages/dashboard/companybranch/list';
import CompanyGroupList from 'src/pages/dashboard/company/group/list';
import EnquiryFollowupListPage from 'src/pages/dashboard/followup/list';
import BookingListPage from 'src/pages/dashboard/booking/list';
import AccountsGroupListPage from 'src/pages/dashboard/accounts/group/list';
import CreditCardListPage from 'src/pages/dashboard/credit-card/list';
import AccountListPage from 'src/pages/dashboard/accounts/account/list';
import AutoMobileAccountListPage from 'src/pages/dashboard/automobile-account/account/list';
import AutoMobileAccountsGroupListPage from 'src/pages/dashboard/automobile-account/group/list';
import AutoMobileCreditCardListPage from 'src/pages/dashboard/automobile-account/creditcard/list';
import SettingsListPage from 'src/pages/dashboard/settings/list';
import CountryListPage from 'src/pages/dashboard/location/country/list';
import StateListPage from 'src/pages/dashboard/location/state/list';
import CityListPage from 'src/pages/dashboard/location/city/list';
import BankPaymentListPage from 'src/pages/dashboard/transaction/cash_bank_entry/bank_payment/list';
import EntriesListPage from 'src/pages/dashboard/transaction/entries/list';
import aMEntriesListPage from 'src/pages/dashboard/automobile-trasaction/entries/list';
import BankReciptListPage from 'src/pages/dashboard/transaction/cash_bank_entry/bank_recipt/list';
import CashPaymentListPage from 'src/pages/dashboard/transaction/cash_bank_entry/cash_payment/list';
import CashReceiptListPage from 'src/pages/dashboard/transaction/cash_bank_entry/cash_receipt/list';
import ContraListPage from 'src/pages/dashboard/transaction/cash_bank_entry/contra/list';
import CnEntryWoStockListPage from 'src/pages/dashboard/transaction/cn_dn_entry/cn_entry_wo_stock/list';
import DnEntryWoStockListPage from 'src/pages/dashboard/transaction/cn_dn_entry/dn_entry_wo_stock/list';
import CnEntryWithStockListPage from 'src/pages/dashboard/transaction/cn_dn_entry/cn_entry_with_stock/list';
import DnEntryWithStockListPage from 'src/pages/dashboard/transaction/cn_dn_entry/dn_entry_with_stock/list';
import JJournalListPage from 'src/pages/dashboard/transaction/journal_entry/journal/list';
import aMJJournalListPage from 'src/pages/dashboard/automobile-trasaction/journal/list';
import automobiledisbursementListPage from 'src/pages/dashboard/automobile-trasaction/disbursement/list';
import InvoiceListPage from 'src/pages/dashboard/transaction/invoice/list';
import autoMobileInvoiceListPage from 'src/pages/dashboard/automobile-trasaction/invoice/list';
import CommonInvoiceListPage from 'src/pages/dashboard/automobile-trasaction/commoninvoice/list';
import autoMobileWorkshopInvoiceListPage from 'src/pages/dashboard/automobile-trasaction/workshop-invoice/list';
import ProductsListPage from 'src/pages/dashboard/products/product-list/list';
import aMProductsListPage from 'src/pages/dashboard/automobile-products/product-list/list';
import aMProductsGroupPage from 'src/pages/dashboard/automobile-products/product-group/list';
import aMProductsCategoryPage from 'src/pages/dashboard/automobile-products/product-category/list';
import aMProductsUnitPage from 'src/pages/dashboard/automobile-products/product-unit/list';
import ProductsGroupPage from 'src/pages/dashboard/products/product-group/list';
import BankModuleList from 'src/pages/dashboard/banklist/list';
import ModelCategoryPage from 'src/pages/dashboard/honda/modelcategory/list';
import EmissionPage from 'src/pages/dashboard/honda/emission/list';
import InsuranceAgentsPage from 'src/pages/dashboard/honda/insuranceagents/list';
import GodownMasterPage from 'src/pages/dashboard/honda/godownmaster/list';
import TaxationMasterList from 'src/pages/dashboard/honda/taxationmaster/list';
import VehiclePurchasePage from 'src/pages/dashboard/honda/vehiclepurchase/list';
import ChallanPageList from 'src/pages/dashboard/honda/challan/list';
import PartIssuesPageList from 'src/pages/dashboard/honda/partisseus/list';
import AreaMasterPage from 'src/pages/dashboard/honda/bookingmaster/areamaster/list';
import RefrenceMasterPage from 'src/pages/dashboard/honda/bookingmaster/refrencemaster/list';
import SchemeMasterPage from 'src/pages/dashboard/honda/bookingmaster/schememaster/list';
import BranchMasterPage from 'src/pages/dashboard/honda/bookingmaster/branchmaster/list';
import FinancierMasterPage from 'src/pages/dashboard/honda/bookingmaster/financiermaster/list';
import ProductModelPage from 'src/pages/dashboard/honda/productmodel/list';
import PricesMasterPage from 'src/pages/dashboard/honda/pricesmaster/list';
import ServiceMasterPage from 'src/pages/dashboard/honda/servicemaster/list';
import ServiceTypeListPage from 'src/pages/dashboard/honda/service-type-master/list';
import ComplaintCodeList from 'src/pages/dashboard/honda/complaintcode/list';
import SourceTypesList from 'src/pages/dashboard/honda/sourcetypes/list';
import FuelLevelList from 'src/pages/dashboard/honda/FuelLevel/list';
import ProductVariantPage from 'src/pages/dashboard/honda/productvariant/list';
import ProductMTOCPage from 'src/pages/dashboard/honda/mtocproduct/list';
import ModelColorPage from 'src/pages/dashboard/honda/modelcolor/list';
import ProductsCategoryPage from 'src/pages/dashboard/products/product-category/list';
import ProductsUnitPage from 'src/pages/dashboard/products/product-unit/list';
import GSTUnitPage from 'src/pages/dashboard/gst/master/gst-unit/list';
import UsersListPage from 'src/pages/dashboard/users/list';
import GSTSlabListPage from 'src/pages/dashboard/gst/master/slab/list';
import GSTCommodityListPage from 'src/pages/dashboard/gst/master/commodity/list';
import GSTBankPaymentListPage from 'src/pages/dashboard/gst/entry/gst-bank-payment/list';
import UtilizationEntryListPage from 'src/pages/dashboard/gst/entry/utilization-entry/list';
import HSNSACListPage from 'src/pages/dashboard/hsnlist/list';
import AutoMobileHSNSACListPage from 'src/pages/dashboard/automobile-gst/hsnlist/list';
import AutoMobileGSTUnitPage from 'src/pages/dashboard/automobile-gst/gst-unit/list';
import AutoMobileGSTSlabListPage from 'src/pages/dashboard/automobile-gst/slab/list';
import AutoMobileGSTCommodityListPage from 'src/pages/dashboard/automobile-gst/commodity/list';
import AutoMobileReceiptListPage from 'src/pages/dashboard/automobile-trasaction/receipt/list';
import TransportListPage from 'src/pages/dashboard/transport/list';
import PaymentOptionListPage from 'src/pages/dashboard/payment-option/list';
import CashCountListPage from 'src/pages/dashboard/cash-count/list';
import BankBookList from 'src/pages/dashboard/bank-book/list';
import AutomobileStockReport from 'src/pages/dashboard/automobiles/reports/stock/list';
import GSTSummaryList from 'src/pages/dashboard/gst-reports/summary/list';
import CashBookList from 'src/pages/dashboard/cash-book/list';
import LedgerReportList from 'src/pages/dashboard/ledger-report/list';
import BankReconciliationPage from 'src/pages/dashboard/bank-reconciliation/list';
import aMBankReconciliationPage from 'src/pages/dashboard/automobile-reports/bank-reconciliation/list';
import aMCashBookList from 'src/pages/dashboard/automobile-reports/cash-book/list';
import aMBankBookList from 'src/pages/dashboard/automobile-reports/bank-book/list';
import SaleStockReport from 'src/pages/dashboard/automobile-reports/sale-book/list';
import CustomReport from 'src/pages/dashboard/automobile-reports/custom/list';
import WebReport from 'src/pages/dashboard/automobile-reports/webreport/list';
import ChallanSalePrintReport from 'src/pages/dashboard/automobile-reports/challansaleprint/list';
import aMLedgerReportList from 'src/pages/dashboard/automobile-reports/ledger-report/list';
import aMAccountLedgerListPage from 'src/pages/dashboard/automobile-reports/account_ledger/list';
import aMAccountReports from 'src/pages/dashboard/automobile-reports/reports';
import JournalEntryListPage from 'src/pages/dashboard/gst/entry/journal-entry/list';
import GSTExpenseListPage from 'src/pages/dashboard/gst/gst-expense/list';
import GSTIncomeListPage from 'src/pages/dashboard/gst/gst-income/list';
import vehicleMasterListPage from 'src/pages/dashboard/work_shop/information/spare_informations/vehilcle_master/list';
import PartsMasterListPage from 'src/pages/dashboard/work_shop/information/spare_informations/parts_informations/parts_master/list';
import MiniMaxPartSetListPage from 'src/pages/dashboard/work_shop/information/spare_informations/parts_informations/mini_max_qty_set/list';
import LocationSetListPage from 'src/pages/dashboard/work_shop/information/spare_informations/parts_informations/location_set/list';
import GroupSetListPage from 'src/pages/dashboard/work_shop/information/spare_informations/parts_informations/group_set/list';
import LocationInformationListPage from 'src/pages/dashboard/work_shop/information/spare_informations/location_information/list';
import DealerListPage from 'src/pages/dashboard/work_shop/information/spare_informations/dealer_information/list';
import ChassisCodePage from 'src/pages/dashboard/work_shop/information/spare_informations/chassic_code/list';
import JobCardListPage from 'src/pages/dashboard/work_shop/information/spare_informations/jobcard_define/list';
import DefaultAccessoriesForVehicle from 'src/pages/dashboard/work_shop/information/spare_informations/default_accessories_for_vehicle/list';
import PartIssueJobCardListPage from 'src/pages/dashboard/work_shop/information/part_issue/job_card/list';
import PartIssueJobCardGSTListPage from 'src/pages/dashboard/work_shop/information/part_issue/job_card_gst/list';
import PartIssuePdiCardListPage from 'src/pages/dashboard/work_shop/information/part_issue/pdi_card/list';
import PartIssuePdiCardGSTListPage from 'src/pages/dashboard/work_shop/information/part_issue/pdi_card_gst/list';
import PartIssueWorkShopIssueListPage from 'src/pages/dashboard/work_shop/information/part_issue/workshop_issue/list';
import LabourInformationListPage from 'src/pages/dashboard/work_shop/information/workshop_information/labour_information/list';
import MechanicInformationListPage from 'src/pages/dashboard/work_shop/information/workshop_information/mechanic_information/list';
import DefectInformationListPage from 'src/pages/dashboard/work_shop/information/workshop_information/defect_information/list';
import ServiceInformationListPage from 'src/pages/dashboard/work_shop/information/workshop_information/service_information/list';
import JobTypeInformationListPage from 'src/pages/dashboard/work_shop/information/workshop_information/job_type_information/list';
import VendorInformationListPage from 'src/pages/dashboard/work_shop/information/workshop_information/vendor_information/list';
import ServiceScheduleMasterListPage from 'src/pages/dashboard/work_shop/information/workshop_information/service_schedule_master/list';
import LabourRateInformationListPage from 'src/pages/dashboard/work_shop/information/workshop_information/labour_rate_information/list';
import AMCSMasterListPage from 'src/pages/dashboard/work_shop/information/workshop_information/amcs_master/list';
import AMCSFreeLabourListPage from 'src/pages/dashboard/work_shop/information/workshop_information/amcs_free_labour/list';
import AccountLedgerListPage from 'src/pages/dashboard/account_ledger/list';

// Reports
import JobCardPrintingListPage from 'src/pages/dashboard/work_shop/reports/general-printing/job-card-printing/list';
import SpareBillPrintingListPage from 'src/pages/dashboard/work_shop/reports/general-printing/spare-bill-printing/list';
import LabourBillPrintingListPage from 'src/pages/dashboard/work_shop/reports/general-printing/labour-bill-printing/list';
import AccessoriesBillPrintingListPage from 'src/pages/dashboard/work_shop/reports/general-printing/accessories-bill-printing/list';
import WarrantyClaimTagListPage from 'src/pages/dashboard/work_shop/reports/general-printing/warranty-claim-tag/list';
import ChallanPrintingSListPage from 'src/pages/dashboard/work_shop/reports/general-printing/challan-printing-spares/list';
import ChallanPrintingAListPage from 'src/pages/dashboard/work_shop/reports/general-printing/challan-printing-accessories/list';
import BTPrintingSparesListPage from 'src/pages/dashboard/work_shop/reports/general-printing/bt-printing-spares/list';
import BTPrintingAccessoriesListPage from 'src/pages/dashboard/work_shop/reports/general-printing/bt-printing-accessories/list';
import ReportsOrderListPage from 'src/pages/dashboard/work_shop/reports/general-printing/order/list';
import VorUDOListPage from 'src/pages/dashboard/work_shop/reports/general-printing/vor-udo/list';
import QuotationListPage from 'src/pages/dashboard/work_shop/reports/general-printing/quotation/list';
import PDIPrintingListPage from 'src/pages/dashboard/work_shop/reports/general-printing/pdi-printing/list';
import IssuedReceiveListPage from 'src/pages/dashboard/work_shop/reports/general-printing/issued-receive/list';
import CashVoucherListPage from 'src/pages/dashboard/work_shop/reports/general-printing/cash-voucher/list';
import BankVoucherListPage from 'src/pages/dashboard/work_shop/reports/general-printing/bank-voucher/list';
import DoorStepFormListPage from 'src/pages/dashboard/work_shop/reports/general-printing/door-step-form/list';
import PurchaseDocPrintListPage from 'src/pages/dashboard/work_shop/reports/general-printing/purchase-document-print/list';
import OutsideLabourDocPrintListPage from 'src/pages/dashboard/work_shop/reports/general-printing/out-side-labour-doc-print/list';
import WorkshopIssuePrintListPage from 'src/pages/dashboard/work_shop/reports/general-printing/workshop-issue-print/list';
///
import BTPrintingAccToShowRoomListPage from 'src/pages/dashboard/work_shop/reports/general-printing/bt-printing-accessories-to-showroom/list';
import BTPrintingSparesOffLineListPage from 'src/pages/dashboard/work_shop/reports/general-printing/bt-printing-spares-off-line/list';
import BackupAllDatabaseListPage from 'src/pages/dashboard/work_shop/utility/backup_all_database/list';
import MechanicEfficiencyListPage from 'src/pages/dashboard/work_shop/mis_reports/mechanicefficiency/list';
import LocationWiseListPage from 'src/pages/dashboard/work_shop/mis_reports/stockreport/locationwise/list';
import DateWiseListPage from 'src/pages/dashboard/work_shop/mis_reports/stockreport/datewise/list';
import OpeningStockListPage from 'src/pages/dashboard/work_shop/mis_reports/stockreport/openingstock/list';
import FlaserePortListPage from 'src/pages/dashboard/work_shop/mis_reports/stockreport/flasereport/list';
import FSCClaimInvoiceListPage from 'src/pages/dashboard/work_shop/mis_reports/stockreport/fscclaiminvoice/list';
import NonMovingPartsListPage from 'src/pages/dashboard/work_shop/mis_reports/stockreport/nonmovingparts/list';
import FastSlowMovingPartsListPage from 'src/pages/dashboard/work_shop/mis_reports/stockreport/fast_slowmovingparts/list';
import SparesprProfitabilityReportListPage from 'src/pages/dashboard/work_shop/mis_reports/stockreport/sparesprofitabilityreport/list';
import GroupwiseStockSummarytListPage from 'src/pages/dashboard/work_shop/mis_reports/stockreport/groupwisestocksummary/list';
import StockAdjustmentReportListPage from 'src/pages/dashboard/work_shop/mis_reports/stockreport/stockadjustmentreport/list';
import BranchTransferSummaryListPage from 'src/pages/dashboard/work_shop/mis_reports/stockreport/branchtransfersummary/list';
import StockAgeingReportListPage from 'src/pages/dashboard/work_shop/mis_reports/stockreport/stockageingreport/list';
import JobCardEntryListPage from 'src/pages/dashboard/work_shop/information/jobcardentry/jobcard/list';
import JobReadyListPage from 'src/pages/dashboard/work_shop/information/jobcardentry/jobready/list';
import VehicleEntryListPage from 'src/pages/dashboard/work_shop/vehicleentry/list';
import ServiceRequestsListPage from 'src/pages/dashboard/work_shop/servicerequests/list';
import PerformedJobsListPage from 'src/pages/dashboard/work_shop/performedjobs/list';
import JobCardDetailListPage from 'src/pages/dashboard/work_shop/job_card_detail/list';
import ReturnOrderListPage from 'src/pages/dashboard/work_shop/return-order/list';
import JobCardPartsListPage from 'src/pages/dashboard/work_shop/job_card_parts/list';
import AccountReports from 'src/pages/dashboard/transaction/reports';
import OverviewBookingPage from 'src/pages/dashboard/booking';

const MainPageList = lazy(() => import('src/pages/dashboard/main'));

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
// const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
// const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
// const CommonInvoiceListPage = lazy(() => import('src/pages/dashboard/automobile-trasaction/commoninvoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
const Department = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
const SalesReports = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

const components = {
  DepartmentListPage,
  BankOfficeDashboard,
  RolesListPage,
  ServiceTypeListPage,
  ComplaintCodeList,
  SourceTypesList,
  FuelLevelList,
  ModuleListPage,
  EnquiryListPage,
  HondaCategory,
  CompanyListPage,
  CompanybranchListPage,
  EnquiryFollowupListPage,
  BookingListPage,
  CompanyGroupList,
  CreditCardListPage,
  AccountListPage,
  AccountsGroupListPage,
  AutoMobileAccountListPage,
  AutoMobileAccountsGroupListPage,
  AutoMobileCreditCardListPage,
  SettingsListPage,
  CountryListPage,
  StateListPage,
  CityListPage,
  EntriesListPage,
  aMEntriesListPage,
  BankPaymentListPage,
  BankReciptListPage,
  CashPaymentListPage,
  CashReceiptListPage,
  ContraListPage,
  CnEntryWoStockListPage,
  DnEntryWoStockListPage,
  DnEntryWithStockListPage,
  JJournalListPage,
  aMJJournalListPage,
  automobiledisbursementListPage,
  InvoiceListPage,
  autoMobileInvoiceListPage,
  CommonInvoiceListPage,
  autoMobileWorkshopInvoiceListPage,
  CnEntryWithStockListPage,
  ProductsListPage,
  aMProductsListPage,
  aMProductsGroupPage,
  aMProductsCategoryPage,
  aMProductsUnitPage,
  ProductsGroupPage,
  BankModuleList,
  ModelCategoryPage,
  EmissionPage,
  InsuranceAgentsPage,
  GodownMasterPage,
  TaxationMasterList,
  VehiclePurchasePage,
  ChallanPageList,
  PartIssuesPageList,
  AreaMasterPage,
  RefrenceMasterPage,
  SchemeMasterPage,
  BranchMasterPage,
  FinancierMasterPage,
  ProductModelPage,
  PricesMasterPage,
  ServiceMasterPage,
  ProductVariantPage,
  ProductMTOCPage,
  ModelColorPage,
  ProductsCategoryPage,
  ProductsUnitPage,
  GSTUnitPage,
  UsersListPage,
  GSTSlabListPage,
  GSTCommodityListPage,
  GSTBankPaymentListPage,
  UtilizationEntryListPage,
  HSNSACListPage,
  AutoMobileHSNSACListPage,
  AutoMobileGSTUnitPage,
  AutoMobileGSTSlabListPage,
  AutoMobileGSTCommodityListPage,
  AutoMobileReceiptListPage,
  TransportListPage,
  PaymentOptionListPage,
  CashCountListPage,
  BankBookList,
  AutomobileStockReport,
  GSTSummaryList,
  CashBookList,
  LedgerReportList,
  BankReconciliationPage,
  aMBankReconciliationPage,
  aMCashBookList,
  aMBankBookList,
  SaleStockReport,
  CustomReport,
  WebReport,
  ChallanSalePrintReport,
  aMLedgerReportList,
  aMAccountLedgerListPage,
  aMAccountReports,
  JournalEntryListPage,
  GSTExpenseListPage,
  GSTIncomeListPage,
  JobCardPrintingListPage,
  SpareBillPrintingListPage,
  LabourBillPrintingListPage,
  AccessoriesBillPrintingListPage,
  WarrantyClaimTagListPage,
  ChallanPrintingSListPage,
  ChallanPrintingAListPage,
  BTPrintingSparesListPage,
  BTPrintingAccessoriesListPage,
  ReportsOrderListPage,
  VorUDOListPage,
  QuotationListPage,
  PDIPrintingListPage,
  IssuedReceiveListPage,
  CashVoucherListPage,
  BankVoucherListPage,
  DoorStepFormListPage,
  PurchaseDocPrintListPage,
  OutsideLabourDocPrintListPage,
  WorkshopIssuePrintListPage,
  BTPrintingAccToShowRoomListPage,
  vehicleMasterListPage,
  PartsMasterListPage,
  MiniMaxPartSetListPage,
  LocationSetListPage,
  GroupSetListPage,
  LocationInformationListPage,
  DealerListPage,
  ChassisCodePage,
  DefaultAccessoriesForVehicle,
  PartIssueJobCardListPage,
  PartIssueJobCardGSTListPage,
  PartIssuePdiCardListPage,
  PartIssuePdiCardGSTListPage,
  PartIssueWorkShopIssueListPage,
  LabourInformationListPage,
  MechanicInformationListPage,
  DefectInformationListPage,
  ServiceInformationListPage,
  JobTypeInformationListPage,
  VendorInformationListPage,
  ServiceScheduleMasterListPage,
  LabourRateInformationListPage,
  AMCSMasterListPage,
  AMCSFreeLabourListPage,
  AccountLedgerListPage,
  BTPrintingSparesOffLineListPage,
  BackupAllDatabaseListPage,
  MechanicEfficiencyListPage,
  LocationWiseListPage,
  DateWiseListPage,
  OpeningStockListPage,
  FlaserePortListPage,
  FSCClaimInvoiceListPage,
  NonMovingPartsListPage,
  FastSlowMovingPartsListPage,
  SparesprProfitabilityReportListPage,
  GroupwiseStockSummarytListPage,
  StockAdjustmentReportListPage,
  BranchTransferSummaryListPage,
  StockAgeingReportListPage,
  JobCardListPage,
  JobCardEntryListPage,
  JobReadyListPage,
  VehicleEntryListPage,
  ServiceRequestsListPage,
  PerformedJobsListPage,
  JobCardDetailListPage,
  ReturnOrderListPage,
  JobCardPartsListPage,
  AccountReports,
  OverviewBookingPage
};

export const dashboardRoutes = (user) => {
  const buildMenuItemTree = (node) => {
    const Component = components[node.component];
    if (
      node.children.length &&
      (node.businesseId === user.businesseId || node.businesseId === null)
    ) {
      return {
        path: node.path,
        element:
          typeof components[node.component] === 'function'
            ? components[node.component]()
            : components[node.component],
        children: node.children
          .filter((x) => x.businesseId === user.businesseId || x.businesseId === null)
          .map((item) => buildMenuItemTree(item)),
      };
    }
    return {
      path: node.path,
      element:
        typeof components[node.component] === 'function'
          ? components[node.component]()
          : components[node.component],
    };
  };

  return user
    ? [
      {
        path: '',
        element: (
          <AuthGuard>
            <DashboardLayout>
              <Suspense fallback={<LoadingScreen />}>
                <Outlet />
              </Suspense>
            </DashboardLayout>
          </AuthGuard>
        ),
        children: [
          { element: <OverviewBookingPage />, index: true },
          ...user.modules
            .filter((x) => x.businesseId === user.businesseId || x.businesseId === null)
            .map((x) => buildMenuItemTree(x)),
          { path: 'sales', element: <OverviewEcommercePage /> },
          { path: 'master', element: <OverviewAnalyticsPage /> },
          { path: 'banking', element: <OverviewBankingPage /> },
          // { path: 'booking', element: <OverviewBookingPage /> },
          { path: 'file', element: <OverviewFilePage /> },
          {
            path: 'user',
            children: [
              { element: <UserProfilePage />, index: true },
              { path: 'profile', element: <UserProfilePage /> },
              { path: 'cards', element: <UserCardsPage /> },
              { path: 'list', element: <UserListPage /> },
              { path: 'new', element: <UserCreatePage /> },
              { path: ':id/edit', element: <UserEditPage /> },
              { path: 'account', element: <UserAccountPage /> },
            ],
          },
          {
            path: 'product',
            children: [
              { element: <ProductListPage />, index: true },
              { path: 'list', element: <ProductListPage /> },
              { path: ':id', element: <ProductDetailsPage /> },
              { path: 'new', element: <ProductCreatePage /> },
              { path: ':id/edit', element: <ProductEditPage /> },
            ],
          },
          {
            path: 'order',
            children: [
              { element: <OrderListPage />, index: true },
              { path: 'list', element: <OrderListPage /> },
              { path: ':id', element: <OrderDetailsPage /> },
            ],
          },
          {
            path: 'invoice',
            children: [
              { element: <InvoiceListPage />, index: true },
              { path: 'list', element: <InvoiceListPage /> },
              { path: ':id', element: <InvoiceDetailsPage /> },
              { path: ':id/edit', element: <InvoiceEditPage /> },
              { path: 'new', element: <InvoiceCreatePage /> },
            ],
          },
          // {
          //   path: 'commoninvoice',
          //   children: [
          //     { element: <CommonInvoiceListPage />, index: true },
          //     { path: ':t_type/:pcategory/:id', element: <CommonInvoiceListPage />, }
          //   ],
          // },
          {
            path: 'post',
            children: [
              { element: <BlogPostsPage />, index: true },
              { path: 'list', element: <BlogPostsPage /> },
              { path: ':title', element: <BlogPostPage /> },
              { path: ':title/edit', element: <BlogEditPostPage /> },
              { path: 'new', element: <BlogNewPostPage /> },
            ],
          },
          {
            path: 'job',
            children: [
              { element: <JobListPage />, index: true },
              { path: 'list', element: <JobListPage /> },
              { path: ':id', element: <JobDetailsPage /> },
              { path: 'new', element: <JobCreatePage /> },
              { path: ':id/edit', element: <JobEditPage /> },
            ],
          },
          {
            path: 'tour',
            children: [
              { element: <TourListPage />, index: true },
              { path: 'list', element: <TourListPage /> },
              { path: ':id', element: <TourDetailsPage /> },
              { path: 'new', element: <TourCreatePage /> },
              { path: ':id/edit', element: <TourEditPage /> },
            ],
          },
          { path: 'file-manager', element: <FileManagerPage /> },
          { path: 'mail', element: <MailPage /> },
          { path: 'chat', element: <ChatPage /> },
          { path: 'calendar', element: <CalendarPage /> },
          { path: 'kanban', element: <KanbanPage /> },
          { path: 'permission', element: <PermissionDeniedPage /> },
          { path: 'blank', element: <BlankPage /> },
        ],
      },
    ]
    : [];
};
