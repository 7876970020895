/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/prop-types */
import sum from 'lodash/sum';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useFieldArray, Controller } from 'react-hook-form';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import MuiPagination from '@mui/material/Pagination';
import { inputBaseClasses } from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
// utils
import { isNaN } from 'lodash';
import { fCurrency } from 'src/utils/format-number';
import { DataGrid, gridPageCountSelector, GridPagination, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
// _mock
import { INVOICE_SERVICE_OPTIONS } from 'src/_mock';
import Paper from '@mui/material/Paper';
// components
import Iconify from 'src/components/iconify';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { alpha, Autocomplete, ListItem, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField } from '@mui/material';
import { fetcher } from 'src/utils/axios';
import { roundOff } from 'src/_mock/constant_funcation';
import { GroupHeader, GroupItems } from 'src/_mock/constant_style';
import { useGetColours } from 'src/api/colours';
import { MaterialReactTable } from 'material-react-table';
import DataGridCustom from './view/invoice-grid-custom';
// ----------------------------------------------------------------------

export default function InvoiceNewEditDetails({ filterTfields }) {
  const { control, setValue, watch, resetField } = useFormContext();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'inv_details',
  });

  const values = watch();
  const totalRow = values.inv_details.filter((item) => item.productId)?.length;
  const totalRate = values.inv_details?.filter((item) => item.productId)
    .map((x) => x.totalrate)
    .reduce((a, b) => a + b, 0) || 0;

  const [rows, setRows] = useState([{
    id: 1, productId: null,
    partno: '',
    qty: 0,
    rate: 0,
    mrp: 0,
    amount: 0,
    dprice: 0,
    IGST: 0,
    SGST: 0,
    CGST: 0,
    gstamt: 0,
    product: null,
  }]);





  const watchedInvDetails = watch('stockdetails');
  const watchedInvDetails1 = watch('inv_details');

  const handleAdd = useCallback(() => {
    append({
      productId: null,
      partno: '',
      qty: 0,
      rate: 0,
      mrp: 0,
      amount: 0,
      dprice: 0,
      IGST: 0,
      SGST: 0,
      CGST: 0,
      gstamt: 0,
      totalrate: 0,
      product: null,
      netamt: 0
    });
  }, [append]);

  const handleRemove = useCallback((index) => {
    remove(index);
  }, [remove]);

  useEffect(() => {
    // Group stock details
    const groupedStockDetails = watchedInvDetails?.reduce((acc, item) => {
      if (!item.partno) return acc;
      const partno = item.partno;

      if (!acc[partno]) {
        acc[partno] = {
          qty: 0,
          rate: 0,
          mrp: 0,
          amount: 0,
          IGST: 0,
          SGST: 0,
          CGST: 0,
          gstamt: 0,
          netamt: 0,
          totalrate: 0,
          product: item.product,
          productId: item.productId,
        };
      }

      if (item.product) {
        acc[partno].qty += 1; // Assuming each item in stockdetails has a qty of 1
        acc[partno].mrp += parseFloat(item.mrp);
        acc[partno].IGST = item.product.IGST || 0;
        acc[partno].SGST = item.product.SGST || 0;
        acc[partno].CGST = item.product.CGST || 0;
        const gstAmount = (acc[partno].mrp / (100 + acc[partno].IGST)) * acc[partno].IGST;
        console.log('gstamount1', gstAmount)
        acc[partno].gstamt += gstAmount;
        acc[partno].netamt += item.netamt;
        acc[partno].totalrate += item.totalrate;
        acc[partno].amount = acc[partno].mrp - gstAmount;
      }

      return acc;
    }, {});

    // Only update if groupedStockDetails is different
    if (groupedStockDetails) {
      const updatedInvDetails = []; // Clone current inv_details

      Object.entries(groupedStockDetails).forEach(([partno, details], idx) => {
        const newDetail = {
          productId: details.productId,
          partno,
          qty: details.qty,
          rate: details.amount,
          mrp: details.mrp,
          amount: details.amount,
          IGST: details.IGST,
          SGST: details.SGST,
          CGST: details.CGST,
          gstamt: details.gstamt,
          product: details.product,
          netamt: details.netamt,
          totalrate: details.totalrate
        };

        if (updatedInvDetails[idx]) {
          // Update existing index
          updatedInvDetails[idx] = { ...updatedInvDetails[idx], ...newDetail };
        } else {
          // Add new entry if index doesn't exist
          updatedInvDetails[idx] = newDetail;
        }
      });

      console.log(updatedInvDetails)
      // Compare old and new arrays before updating
      // if (JSON.stringify(watchedInvDetails) !== JSON.stringify(updatedInvDetails)) {
      //   updatedInvDetails.forEach((x, index) => {
      //     update(index, x);
      //   })
      // }
    }
  }, [update, watchedInvDetails]); // Removed values.inv_details to prevent circular dependency


  const emptyrowcount = values.inv_details.filter(x => !x.partno).length;
  if (!emptyrowcount)
    handleAdd()
  const handleChange = useCallback(
    async (event, index, field) => {
      console.log('change index', index)
      const value = field === 'partno' ? event.target.value : Number(event.target.value);
      // setValue(`inv_details[${index}].${field}`, value);
      let productdata = {}
      // Check if the field is partno and fetch product details if so
      if (field === 'partno' && value) {
        try {
          const URL = `automobile/products/${value}?field=partno`;
          const { data } = await fetcher(URL);
          productdata = data;
          if (data?.partno === value) {
            // Update fields based on the fetched product data
            setValue(`inv_details[${index}].mrp`, roundOff(data.sal_rate || 0));
            setValue(`inv_details[${index}].CGST`, data.CGST || 0);
            setValue(`inv_details[${index}].SGST`, data.SGST || 0);
            setValue(`inv_details[${index}].IGST`, data.IGST || 0);
            setValue(`inv_details[${index}].product`, data);
            setValue(`inv_details[${index}].productId`, data.id);
            setValue(`inv_details[${index}].qty`, 1);

          }
        } catch (error) {
          console.error("Error fetching product details:", error);
        }
      } else if (!value) {
        update(index, {
          productId: null,
          partno: '',
          qty: 0,
          rate: 0,
          mrp: 0,
          amount: 0,
          dprice: 0,
          IGST: 0,
          SGST: 0,
          CGST: 0,
          gstamt: 0,
          totalrate: 0,
          product: null,
        })
      }

      // Perform calculations for all fields, including when field is 'partno'
      const updatedDetails = values.inv_details.map((item, idx) => {
        const { qty, mrp, IGST, dprice, productId, rate, totalrate } = item;
        const currentQty = roundOff(field === 'qty' && idx === index ? value : qty);
        const currentMrp = roundOff(field === 'mrp' && idx === index ? value : mrp);
        const currentRate = roundOff(field === 'rate' && idx === index ? value : rate);
        const currentTotalRate = roundOff(field === 'totalrate' && idx === index ? value : (rate * qty));
        const currentDprice = roundOff(field === 'dprice' && idx === index ? value : dprice);
        const currentIGST = field === 'partno' && idx === index ? productdata?.IGST || 0 : IGST;
        const denominator = 100 + currentIGST;
        const netamt = roundOff((currentTotalRate - currentDprice));
        const gstAmount = roundOff((netamt / 100) * currentIGST);
        // const amount = roundOff(netamt + gstAmount);
        return {
          qty: currentQty ? roundOff(currentQty) : 0,
          mrp: currentMrp ? roundOff(currentMrp) : 0,
          rate: currentRate ? roundOff(currentRate) : 0,
          totalrate: currentTotalRate ? roundOff(currentTotalRate) : 0,
          dprice: currentDprice ? roundOff(currentDprice) : 0,
          gstamt: gstAmount ? roundOff(gstAmount) : 0,
          netamt: netamt ? roundOff(netamt) : 0,
          // amount: amount ? roundOff(amount) : 0,
        };
      });

      // Set the updated values for the specified index
      update(index, {
        ...fields[index],
        [field]: value,
        rate: roundOff(updatedDetails[index].rate),
        totalrate: roundOff(updatedDetails[index].totalrate),
        gstamt: roundOff(updatedDetails[index].gstamt),
        amount: roundOff(updatedDetails[index].amount),
        netamt: roundOff(updatedDetails[index].netamt),
      })
    },
    [fields, setValue, update, values.inv_details]
  );

  // useEffect(() => {
  //   console.log('setrows')
  //   // if (JSON.stringify(values.stockdetails) !== JSON.stringify(rows))
  //   setRows(values.inv_details.map((x, index) => ({ ...x, id: index + 1, netamt: isNaN(parseInt(x.dprice, 10)) ? x.totalrate : x.totalrate - parseInt(x.dprice, 10) })))
  // }, [values.inv_details])



  const handleRowUpdate = (newRow) => {
    console.log(newRow)
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === newRow.id ? { ...newRow, netamt: isNaN(parseInt(newRow.dprice, 10)) ? newRow.totalrate : newRow.totalrate - parseInt(newRow.dprice, 10) } : row))
    );
    return newRow;
  };
  const handleAddRow = () => {
    setRows((prevRows) => [
      ...prevRows,
      {
        id: prevRows.length + 1,
        partno: '',
        qty: 0,
        rate: 0,
        totalrate: 0,
        mrp: 0,
        amount: 0,
        dprice: 0,
        IGST: 0,
        SGST: 0,
        CGST: 0,
        gstamt: 0,
        product: null,
      },
    ]);
  };
  // eslint-disable-next-line react/no-unstable-nested-components
  const Pagination = ({ page, onPageChange, className }) => {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event, newPage - 1);
        }}
      />
    );
  }
  // eslint-disable-next-line react/no-unstable-nested-components
  // const CustomPagination = (props) => <Stack
  //   spacing={0}
  //   direction={{ xs: 'column', md: 'row' }}
  //   alignItems="center"
  //   justifyContent="space-between"
  // >
  //   {/* <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
  //     <div style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold' }}>
  //       Total Entry: {totalRow}
  //     </div>
  //     <div style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold' }}>
  //       NET Amount: {totalRate.toFixed(2)}
  //     </div>
  //   </div> */}
  //   <GridPagination ActionsComponent={Pagination} {...props} />
  // </Stack>

  // const columns = useMemo(() => [
  //   {
  //     field: 'partno',
  //     headerName: 'Code',
  //     width: 200,
  //     editable: true,
  //     footer: (rows) => {
  //       const total = rows.filter((row) => row.partno).length;
  //       return `Total Entry: ${total}`;
  //     },
  //   },
  //   {
  //     field: 'productName',
  //     headerName: 'Product',
  //     width: 200,
  //     editable: false,
  //     valueGetter: (params) => params.row?.product?.name || '',
  //   },

  //   {
  //     field: 'qty',
  //     headerName: 'Qty',
  //     width: 50,
  //     editable: false,
  //   },
  //   {
  //     field: 'rate',
  //     headerName: 'Rate',
  //     width: 80,
  //     editable: false
  //   },
  //   {
  //     field: 'totalrate',
  //     headerName: 'Amount',
  //     width: 100,
  //     editable: false
  //   },
  //   {
  //     field: 'dprice',
  //     headerName: 'Disc.',
  //     width: 60,
  //     editable: true,
  //     footer: (rows) => {
  //       const total = rows.reduce((sum, row) => sum + (parseFloat(row.dprice) || 0), 0);
  //       return `Total: ${total.toFixed(2)}`;
  //     },
  //   },
  //   {
  //     field: 'IGST',
  //     headerName: 'GST%',
  //     width: 60,
  //     editable: true,
  //   },

  //   // {
  //   //   field: 'mrp',
  //   //   headerName: 'MRP',
  //   //   width: 100,
  //   //   editable: true,
  //   //   footer: (rows) => {
  //   //     const total = rows.reduce((sum, row) => sum + (parseFloat(row.mrp) || 0), 0);
  //   //     return `Total: ${total.toFixed(2)}`;
  //   //   },
  //   // },
  //   {
  //     field: 'gstamt',
  //     headerName: 'GST Amt',
  //     width: 100,
  //     editable: true,
  //     footer: (rows) => {
  //       const total = rows.reduce((sum, row) => sum + (parseFloat(row.gstamt) || 0), 0);
  //       return `Total: ${total.toFixed(2)}`;
  //     },
  //   },
  //   {
  //     field: 'netamt',
  //     headerName: 'Net Amount',
  //     width: 100,
  //     editable: true,
  //     footer: (rows) => {
  //       const total = rows.reduce((sum, row) => sum + (parseFloat(row.amount) || 0), 0);
  //       return `Total: ${total.toFixed(2)}`;
  //     },
  //   },
  // ], []);
  const columns = useMemo(() => [
    {
      accessorKey: 'partno',
      header: 'Code',
      size: 200, // Equivalent to width
      enableEditing: true, // Editable column
      muiEditTextFieldProps: ({ cell }) => ({
        type: 'number',
        onBlur: (event) => {
          const newValue = event.target.value; // Get the current value from the event
          const oldValue = cell.getValue(); // Get the previous value from the cell
          if (newValue !== oldValue) { // Check if the value has changed
            console.log("Value changed from", oldValue, "to", newValue);
            handleChange(event, cell.row.index, 'partno'); // Call handleStockChange
          } else {
            console.log("No change in value, skipping handleStockChange");
          }
        }
      }),
      Footer: ({ table }) => {
        // Calculate the total for the 'rate' column
        const total = table
          .getFilteredRowModel()
          .rows.filter(x => x.original.partno).length;

        return <span>Total Entry: {total}</span>;
      },
    },
    {
      accessorKey: 'productName',
      header: 'Product',
      size: 200,
      enableEditing: false,
      Cell: ({ row }) => row.original.product?.name || '', // Equivalent to valueGetter
    },
    {
      accessorKey: 'qty',
      header: 'Qty',
      size: 50,
      enableEditing: false,
      muiEditTextFieldProps: ({ cell }) => ({
        type: 'number',
        onBlur: (event) => {
          const newValue = event.target.value; // Get the current value from the event
          const oldValue = cell.getValue(); // Get the previous value from the cell
          if (parseInt(newValue, 10) !== parseInt(oldValue, 10)) { // Check if the value has changed
            console.log("Value changed from", oldValue, "to", newValue);
            handleChange(event, cell.row.index, 'qty'); // Call handleStockChange
          } else {
            console.log("No change in value, skipping handleStockChange");
          }
        }
      }),
      Footer: ({ table }) => {
        // Calculate the total for the 'rate' column
        const total = table
          .getFilteredRowModel()
          .rows.reduce((sum, row) => sum + (parseFloat(row.original.qty) || 0), 0);

        return <span>{total}</span>;
      },
    },
    {
      accessorKey: 'rate',
      header: 'Rate',
      size: 80,
      enableEditing: true,
      muiEditTextFieldProps: ({ cell }) => ({
        type: 'number',
        onBlur: (event) => {
          const newValue = event.target.value; // Get the current value from the event
          const oldValue = cell.getValue(); // Get the previous value from the cell
          if (parseInt(newValue, 10) !== parseInt(oldValue, 10)) { // Check if the value has changed
            console.log("Value changed from", oldValue, "to", newValue);
            handleChange(event, cell.row.index, 'rate'); // Call handleStockChange
          } else {
            console.log("No change in value, skipping handleStockChange");
          }
        }
      }),
      Footer: ({ table }) => {
        // Calculate the total for the 'rate' column
        const total = table
          .getFilteredRowModel()
          .rows.reduce((sum, row) => sum + (parseFloat(row.original.rate) || 0), 0);


        return <span>{total}</span>;
      },
    },
    {
      accessorKey: 'totalrate',
      header: 'Amount',
      size: 100,
      enableEditing: false,
      muiEditTextFieldProps: ({ cell }) => ({
        type: 'number',
        onBlur: (event) => {
          const newValue = event.target.value; // Get the current value from the event
          const oldValue = cell.getValue(); // Get the previous value from the cell
          if (parseInt(newValue, 10) !== parseInt(oldValue, 10)) { // Check if the value has changed
            console.log("Value changed from", oldValue, "to", newValue);
            handleChange(event, cell.row.index, 'totalrate'); // Call handleStockChange
          } else {
            console.log("No change in value, skipping handleStockChange");
          }
        }
      }),
      Footer: ({ table }) => {
        // Calculate the total for the 'totalrate' column
        const total = table
          .getFilteredRowModel()
          .rows.reduce((sum, row) => sum + (parseFloat(row.original.totalrate) || 0), 0);

        return <span>{total}</span>;
      },
    },
    {
      accessorKey: 'dprice',
      header: 'Disc.',
      size: 60,
      enableEditing: true,
      muiEditTextFieldProps: ({ cell }) => ({
        type: 'number',
        onBlur: (event) => {
          const newValue = event.target.value; // Get the current value from the event
          const oldValue = cell.getValue(); // Get the previous value from the cell
          if (parseInt(newValue, 10) !== parseInt(oldValue, 10)) { // Check if the value has changed
            console.log("Value changed from", oldValue, "to", newValue);
            console.log('call handleChange')
            handleChange(event, cell.row.index, 'dprice'); // Call handleStockChange
          } else {
            console.log("No change in value, skipping handleStockChange");
          }
        }
      }),
      Footer: ({ table }) => {
        // Calculate the total for the 'dprice' column
        const total = table
          .getFilteredRowModel()
          .rows.reduce((sum, row) => sum + (roundOff(row.original.dprice)), 0);

        return <span>{total}</span>;
      },
    },
    {
      accessorKey: 'IGST',
      header: 'GST%',
      size: 60,
      enableEditing: true,
      muiEditTextFieldProps: ({ cell }) => ({
        type: 'number',
        onBlur: (event) => {
          const newValue = event.target.value; // Get the current value from the event
          const oldValue = cell.getValue(); // Get the previous value from the cell
          if (parseInt(newValue, 10) !== parseInt(oldValue, 10)) { // Check if the value has changed
            console.log("Value changed from", oldValue, "to", newValue);
            handleChange(event, cell.row.index, 'IGST'); // Call handleStockChange
          } else {
            console.log("No change in value, skipping handleStockChange");
          }
        }
      }),
    },
    {
      accessorKey: 'gstamt',
      header: 'GST Amt',
      size: 100,
      enableEditing: false,
      Footer: ({ table }) => {
        const total = table
          .getRowModel()
          .rows.reduce((sum, row) => sum + (parseFloat(row.original.gstamt) || 0), 0);
        return `${total.toFixed(2)}`;
      },
    },
    {
      accessorKey: 'netamt',
      header: 'Net Amount',
      size: 100,
      enableEditing: false,
      Footer: ({ table }) => {
        const total = table
          .getRowModel()
          .rows.reduce((sum, row) => sum + (parseFloat(row.original.netamt) || 0), 0);
        return `${total.toFixed(2)}`;
      },
    },
  ], [handleChange]);
  const transformedData = useMemo(() => fields.map((row, index) => ({
    ...row,
    id: row.id || index + 1, // Ensure unique IDs
  })), [fields]);

  // Aggregator logic for totals
  const aggregatedTotals = useMemo(() => rows?.reduce(
    (acc, row) => {
      acc.qty += row.qty || 0;
      acc.totalrate += parseFloat(row.totalrate) || 0;
      acc.dprice += parseFloat(row.dprice) || 0;
      acc.gstamt += parseFloat(row.gstamt) || 0;
      acc.netamt += parseFloat(row.netamt) || 0;
      return acc;
    },
    { qty: 0, totalrate: 0, dprice: 0, gstamt: 0, netamt: 0 }
  ), [rows]);
  return (
    <Box sx={{ pt: 0 }}>
      {/* <TableContainer
        component={Paper}
        sx={{
          overflow: 'auto',
          height: '25vh',
          width: '100%',
          margin: 'auto',
        }}
      >
        <Table
          stickyHeader
          aria-label="simple table"
          sx={{
            '& .MuiTableCell-sizeMedium': {
              padding: '0px !important',
              paddingLeft: '2px !important',
            },
            '& fieldset': { borderRadius: '0px', border: 'none' },
            '& .MuiInputBase-input': { padding: '0px !important', border: 'none', fontSize: '12px !important' },
            '& .MuiOutlinedInput-root': { padding: '0px !important' },
            '& .autoComplete>div': { padding: '0px !important' },
            '& .MuiTableCell-root': {
              border: '0.01px solid #e9ecee',
              fontSize: '12px !important'
            },
            '& .MuiTableCell-head': {
              padding: '2px !important',
              borderRight: '1px solid #d0d1d2 !important',
            },
            '& .MuiTableCell-footer': {
              padding: '2px !important',
            },
            '& .MuiTableCell-head:last-child': {
              borderRight: '0px !important',
            },
            '&:last-child td': {
              borderColor: '#e9ecee !important',
            },
          }}
        >
          <TableHead sx={{ position: 'sticky', zIndex: '11111' }}>
            <TableRow>
              <TableCell sx={{ width: 20, textAlign: 'center' }}>sr.</TableCell>
              <TableCell sx={{ width: 50, textAlign: 'center' }}>Code</TableCell>
              <TableCell sx={{ width: 300, textAlign: 'center' }}>
                Product Name
              </TableCell>
              <TableCell sx={{ width: 60, textAlign: 'center' }}>MRP</TableCell>
              <TableCell sx={{ width: 40, textAlign: 'center' }}>Qty</TableCell>
              <TableCell sx={{ width: 50, textAlign: 'center' }}>
                Disc.
              </TableCell>
              <TableCell sx={{ width: 50, textAlign: 'center' }}>
                GST%
              </TableCell>
              <TableCell sx={{ width: 60, textAlign: 'center' }}>
                Net Rate
              </TableCell>
              <TableCell sx={{ width: 60, textAlign: 'center' }}>
                GST Amt
              </TableCell>
              <TableCell sx={{ width: 70, textAlign: 'center' }}>Net Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((xdata, index) => (
              <TableRow
                key={xdata.id + index}
                sx={{
                  textAlign: 'center',
                  position: 'sticky',
                  left: 0,
                  backgroundColor:
                    values.inv_details[index]?.t_type === 8 &&
                    !xdata?.instock &&
                    xdata?.instock !== null &&
                    '#ffbfbf',
                }}
              >
                <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                <TableCell>
                  <RHFTextField
                    name={`inv_details[${index}].partno`}
                    size="small"
                    fullWidth
                    variant="outlined"
                    onBlur={(event) => handleChange(event, index, 'partno')}
                  />
                </TableCell>
                <TableCell>
                  {values.inv_details[index]?.product?.name || ''}
                </TableCell>
                <TableCell sx={{ textAlign: 'end' }}>
                  <RHFTextField
                    name={`inv_details[${index}].mrp`}
                    size="small"
                    fullWidth
                    variant="outlined"
                    type="number"
                    onChange={(event) => handleChange(event, index, 'mrp')}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: 'end' }}>
                  <RHFTextField
                    name={`inv_details[${index}].qty`}
                    size="small"
                    fullWidth
                    variant="outlined"
                    type="number"
                    onChange={(event) => handleChange(event, index, 'qty')}
                  />
                </TableCell>
                <TableCell>
                  <RHFTextField
                    name={`inv_details[${index}].dprice`}
                    size="small"
                    fullWidth
                    variant="outlined"
                    onChange={(event) => handleChange(event, index, 'dprice')}
                  />
                </TableCell>
                <TableCell>
                  {values.inv_details[index]?.IGST || ''}
                </TableCell>
                <TableCell>
                  {values.inv_details[index]?.rate || ''}
                </TableCell>
                <TableCell>
                  {values.inv_details[index]?.gstamt || ''}
                </TableCell>
                <TableCell>
                  {values.inv_details[index]?.amount || ''}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter sx={{
            bottom: '0',
            position: 'sticky',
            zIndex: '11111',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}>
            <TableRow>
              <TableCell colSpan={3} sx={{ width: 20, textAlign: 'center' }} >
                Total
              </TableCell>
              <TableCell sx={{ width: 60, textAlign: 'center' }}>
                {totals.totalMrp.toFixed(2)}
              </TableCell>
              <TableCell sx={{ width: 40, textAlign: 'center' }}>
                {totals.totalQty}
              </TableCell>
              <TableCell sx={{ width: 50, textAlign: 'center' }}>
                {totals.totalDprice.toFixed(2)}
              </TableCell>
              <TableCell sx={{ width: 50, textAlign: 'center' }} />
              <TableCell sx={{ width: 60, textAlign: 'center' }}>
                {totals.totalNetRate.toFixed(2)}
              </TableCell>
              <TableCell sx={{ width: 60, textAlign: 'center' }}>
                {totals.totalGstAmt.toFixed(2)}
              </TableCell>
              <TableCell sx={{ width: 70, textAlign: 'center' }}>
                {totals.totalAmount.toFixed(2)}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer> */}

      {/* <DataGrid
        state={{
          keyboard: {
            cell: null,
            columnHeader: null,
            isMultipleKeyPressed: false,
          }
        }}
        experimentalFeatures={{ newEditingApi: true }}
        hideFooterSelectedRowCount
        rows={rows}
        rowHeight={20}
        columnHeaderHeight={20}
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            fontSize: '12px', // Header font size
          },
          '& .MuiDataGrid-cell': {
            fontSize: '11px', // Cell font size
          },
          // Custom styles for pagination
          '& .MuiDataGrid-footerContainer': {
            height: 20, // Set pagination height
            minHeight: 20
          },
          '& .MuiTablePagination-root': {
            fontSize: '12px', // Set pagination font size
            margin: '0px'
          },
          // Adjust font size for dropdown and pagination label
          '& .MuiTablePagination-selectLabel, & .MuiTablePagination-input, & .MuiTablePagination-displayedRows, & .MuiTablePagination-actions': {
            fontSize: '12px', // Font size for select label and input
            margin: '0px'

          },
        }}
        columns={columns}
        editMode="cell"
        processRowUpdate={handleRowUpdate}
        onCellEditStop={(params, event) => {
          const { id, field } = params;
          if (event?.target?.value) {
            // handleChange(event, id, field);
          }
        }}
        pageSize={5}
        initialState={{ density: 'compact', pagination: { paginationModel: { page: 1, pageSize: 5 } } }}
        pageSizeOptions={[5, 10, 25, 100]}

      /> */}

      <Controller
        name="inv_details"
        control={control}
        render={({ field }) => (
          <MaterialReactTable
            columns={columns}
            data={transformedData}
            enableColumnFooter
            enableStickyFooter
            enableStickyHeader
            enableEditing // Enable inline editing
            editDisplayMode='table' // Inline cell editing mode
            enableColumnActions={false} // Disable column actions globally
            enableColumnFilters={false} // Disable column filters globally
            enableSorting={false} // Disable sorting globally
            enablePagination // Optional: Disable pagination if not needed
            enableRowSelection={false} // Optional: Disable row selection if not needed
            enableRowNumbers // Optionally display row numbers
            initialState={{
              pagination: {
                pageSize: 5, // Default page size
                pageIndex: 0, // Default page index
              },
              density: 'compact', // Compact table density
            }}
            // defaultColumn={{
            //   muiTopToolbarProps: {
            //     '& .MuiBox-root': {
            //       padding: '0px',
            //       paddingTop: '0px',
            //       paddingBottom: '0px',
            //       lineHeight: '16px',
            //     },
            //     fontSize: '11px', // Header font size
            //     padding: '0px',
            //     paddingTop: '0px',
            //     paddingBottom: '0px',
            //     lineHeight: '16px',
            //   },
            //   muiTableHeadCellProps: {
            //     sx: {
            //       '& .MuiTableCell-head, .MuiTableCell-stickyHeader': {
            //         fontSize: '11px', // Header font size
            //         padding: '0px',
            //         paddingTop: '0px',
            //         paddingBottom: '0px',
            //       },
            //       fontSize: '11px', // Header font size
            //       padding: '0px',
            //       paddingTop: '0px',
            //       paddingBottom: '0px',
            //       lineHeight: '16px',
            //     },
            //   },
            //   muiTableBodyCellProps: {
            //     sx: {
            //       '& input': {
            //         fontSize: '11px', // Cell font size

            //         padding: '0',
            //       },
            //       fontSize: '11px', // Cell font size
            //       padding: '0px'
            //     },
            //   },
            //   muiTableFooterCellProps: {
            //     sx: {
            //       fontSize: '11px', // Cell font size
            //       padding: '0px',
            //       lineHeight: '16px',
            //     }

            //   }
            // }}
            muiTablePaginationProps={{
              rowsPerPageOptions: [5, 10, 25, 100], // Page size options
              labelRowsPerPage: 'Rows per page:',
              labelDisplayedRows: ({ from, to, count }) => `${from}-${to} of ${count}`,
              sx: {
                fontSize: '12px', // Pagination font size
              },
            }}
          />
        )}
      />
      {/* <Controller
        name="inv_details"
        control={control}
        render={({ field }) =>
          <DataGridCustom columns={columnsmuidatagrid}
            data={field.value.map((row, index) => ({
              ...row,
              id: row.id || index + 1, // Ensure each row has a unique id
            }))} />}
      /> */}

      {/* <Divider sx={{ my: 3, borderStyle: 'dashed' }} /> */}

      {/* <Stack
        spacing={3}
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-end', md: 'center' }}
      >

        <Stack
          spacing={2}
          justifyContent="flex-end"
          direction={{ xs: 'column', md: 'row' }}
          sx={{ width: 1 }}
        >

          <RHFTextField
            size="small"
            label="Discount($)"
            name="discount"
            type="number"
            sx={{ maxWidth: { md: 120 } }}
          />

          <RHFTextField
            size="small"
            label="Taxes(%)"
            name="taxes"
            type="number"
            sx={{ maxWidth: { md: 120 } }}
          />
        </Stack>
      </Stack> */}

    </Box>
  );
}
