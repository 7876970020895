import PropTypes from 'prop-types';
import { forwardRef, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import Slide from '@mui/material/Slide';
import { useDebounce } from 'src/hooks/use-debounce';
// routes
import { paths } from 'src/routes/paths';
import Grid from '@mui/material/Unstable_Grid2';
import { useRouter } from 'src/routes/hooks';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
// _mock
import { _addressBooks, _mock } from 'src/_mock';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import FormProvider, { RHFTextField } from 'src/components/hook-form';
//
// utils
import { fPercent, fCurrency, fNumber, formatCurrency } from 'src/utils/format-number';
// components
import Rating from '@mui/material/Rating';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { AuthContext } from 'src/auth/context/jwt';
import { NumericFormat } from 'react-number-format';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { isNaN } from 'lodash';
import { create, fetcher, update } from 'src/utils/axios';
import Typography from '@mui/material/Typography';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import { alpha, Backdrop, CircularProgress, Tooltip } from '@mui/material';
import { roundOff, sanitizeComplexObject } from 'src/_mock/constant_funcation';
import LinearProgress from '@mui/material/LinearProgress';
import DataGridCustom from 'src/sections/_examples/mui/data-grid-view/data-grid-custom';
import InvoiceNewEditDetails from './invoice-new-edit-details';
import InvoiceStockDetails from './invoice-stock-details';
import InvoiceNewEditAddress from './invoice-new-edit-address';
import InvoiceNewEditStatusDate from './invoice-new-edit-status-date';
import InvoiceGridCustom from './view/invoice-grid-custom';



const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& td': {
    textAlign: 'right',
    borderBottom: 'none',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));
// ----------------------------------------------------------------------
const _dataGrid = [...Array(5)].map((_, index) => {
  const status =
    (index % 2 && 'online') || (index % 3 && 'alway') || (index % 4 && 'busy') || 'offline';

  return {
    id: _mock.id(index),
    status,
    email: _mock.email(index),
    name: _mock.fullName(index),
    age: _mock.number.age(index),
    lastLogin: _mock.time(index),
    isAdmin: _mock.boolean(index),
    lastName: _mock.lastName(index),
    rating: _mock.number.rating(index),
    firstName: _mock.firstName(index),
    performance: _mock.number.percent(index),
  };
});
const columns = [
  { field: 'id', flex: 0.5 }, // roughly 5%
  { field: 'code', headerName: 'Code', flex: 3, editable: true }, // roughly 10%
  {
    field: 'product',
    headerName: 'Product Name',
    flex: 4, // roughly 40%
    editable: false,
    valueGetter: (params) => params.row?.product?.name || '',
  },
  { field: 'mrp', headerName: 'MRP', flex: 1, editable: true }, // roughly 10%
  { field: 'unit', headerName: 'Unit', flex: 1, editable: false }, // roughly 10%
  { field: 'qty', headerName: 'Qty', flex: 1, editable: true }, // roughly 10%
  { field: 'rate', headerName: 'Rate', flex: 1, editable: true },
  { field: 'dprice', headerName: 'Disc.(₹)', flex: 1, editable: true },
  { field: 'drate', headerName: 'Disc.(%)', flex: 1, editable: true },
  { field: 'amount', headerName: 'Amount', flex: 1, editable: true },
  {
    field: 'action',
    headerName: ' ',
    align: 'right',
    width: 80, // can keep as fixed since actions usually require less dynamic space
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => (
      <IconButton onClick={() => console.info('ID', params.row.id)}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>
    ),
  },
];
const commonfield = {
  8: {
    3: {
      inv_details: {
        show: true,
        label: 'inv_details'
      },
      stockdetails: {
        show: true,
        label: 'stockdetails'
      },
      entry_details: {
        show: true,
        label: 'stockdetails',
        list: {
          1: {
            show: false,
            label: 'Purchase',
          },
          2: {
            show: false,
            label: 'Sale',
          },
          3: {
            show: false,
            label: '',
          }
        }
      },
      identity: {
        show: true,
        label: 'Doc No.'
      },
      bill_no: {
        show: true,
        label: 'Invoice No'
      },
      b_date: {
        show: true,
        label: 'Invoice Date'
      },
      dt: {
        show: true,
        label: 'Doc Date'
      },
      import: {
        show: true,
        label: 'Import'
      },
      truckNo: {
        show: true,
        label: 'Truck No.'
      },
      partyNo: {
        show: true,
        label: 'Party No.'
      },
      inTras: {
        show: true,
        label: 'In Transit'
      },
      receivedDate: {
        show: true,
        label: 'Rec. Date'
      },
      transportName: {
        show: true,
        label: 'Transport Name'
      },
      LRefNo: {
        show: true,
        label: 'Load Ref. No'
      },
      godownId: {
        show: true,
        label: 'Location'
      }
    },
    5: {
      inv_details: {
        show: true,
        label: 'inv_details'
      },
      entry_details: {
        show: true,
        label: 'stockdetails',
        list: {
          1: {
            show: false,
            label: 'Purchase',
          },
          2: {
            show: false,
            label: 'Sale',
          },
          3: {
            show: false,
            label: '',
          }
        }
      },
      identity: {
        show: true,
        label: 'Doc No.'
      },
      bill_no: {
        show: true,
        label: 'Invoice No'
      },
      b_date: {
        show: true,
        label: 'Invoice Date'
      },
      dt: {
        show: true,
        label: 'Doc Date'
      },
      import: {
        show: true,
        label: 'Import'
      },
      truckNo: {
        show: true,
        label: 'Truck No.'
      },
      partyNo: {
        show: true,
        label: 'Party No.'
      },
      inTras: {
        show: true,
        label: 'In Transit'
      },
      receivedDate: {
        show: true,
        label: 'Rec. Date'
      },
      transportName: {
        show: true,
        label: 'Transport Name'
      },
      LRefNo: {
        show: true,
        label: 'Load Ref. No'
      },
      godownId: {
        show: true,
        label: 'Location'
      }
    },
    8: {
      inv_details: {
        show: true,
        label: 'inv_details'
      },
      entry_details: {
        show: true,
        label: 'stockdetails',
        list: {
          1: {
            show: false,
            label: 'Purchase',
          },
          2: {
            show: false,
            label: 'Sale',
          },
          3: {
            show: false,
            label: '',
          }
        }
      },
      identity: {
        show: true,
        label: 'Doc No.'
      },
      bill_no: {
        show: true,
        label: 'Invoice No'
      },
      b_date: {
        show: true,
        label: 'Invoice Date'
      },
      dt: {
        show: true,
        label: 'Doc Date'
      },
      partyNo: {
        show: true,
        label: 'Party No.'
      },
      egforitc: {
        show: true,
        label: 'ITC'
      },

    }

  },
  9: {
    3: {
      identity: {
        show: true,
        label: 'Invoice Number'
      },
      dt: {
        show: true,
        label: 'Invoice Date'
      }
    }
  },
  10: {
    3: {
      identity: {
        show: true,
        label: 'Invoice Number'
      },
      dt: {
        show: true,
        label: 'Invoice Date'
      }
    }
  }
};
const getCommonField = (fieldName, values) => {
  const tType = values.t_type;
  const pCategoryId = values.pcategoryId;

  // Check if field exists for the specific t_type and pcategoryId or if it should be common for t_type 8
  const fieldConfig = commonfield?.[fieldName] ||
    commonfield?.[tType]?.[pCategoryId]?.[fieldName] ||
    (commonfield?.[tType]?.[fieldName]);

  return fieldConfig;
};

const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);
export default function InvoiceNewEditForm({ goDownData, tfields, currentInvoice, invsetting, create_edit, data: { productcategories } }) {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const fileRef = useRef(null);
  const { user } = useContext(AuthContext);
  const [entry, setEntry] = useState(null);

  const [filterTfields, setFilterTfields] = useState([]);
  const handleAttach = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const loadingSave = useBoolean();
  const loadingSend = useBoolean();

  const [isLoading, setisLoading] = useState(true);
  const [partyLoading, setPartyLoading] = useState(false);
  const [party, setParty] = useState({ id: null });
  const [partyInputValue, setPartyInputValue] = useState("");
  const [partyOptions, setPartyOptions] = useState([]);
  const [partyCode, setPartyCode] = useState('');
  const NewInvoiceSchema = Yup.object().shape({
    invoiceTo: Yup.mixed().nullable().required('Invoice to is required'),
    createDate: Yup.mixed().nullable().required('Create date is required'),
    dueDate: Yup.mixed()
      .required('Due date is required')
      .test(
        'date-min',
        'Due date must be later than create date',
        (value, { parent }) => value.getTime() > parent.createDate.getTime()
      ),
    // not required
    taxes: Yup.number(),
    status: Yup.string(),
    discount: Yup.number(),
    shipping: Yup.number(),
    invoiceFrom: Yup.mixed(),
    totalAmount: Yup.number(),
    identity: Yup.string(),
  });


  const defaultValues = useMemo(
    () => ({
      identity: entry?.identity || '',
      t_type: entry?.t_type || '',
      in_type: entry?.in_type || '',
      dt: entry?.dt || new Date(),
      truckNo: entry?.truckNo || '',
      egforitc: entry?.egforitc || '',
      partyNo: entry?.partyNo || '',
      gst_no: entry?.gst_no || '',
      transportName: entry?.transportName || '',
      bill_no: entry?.bill_no || '',
      b_date: entry?.b_date || new Date(),
      receivedDate: entry?.receivedDate || new Date(),
      LRefNo: entry?.LRefNo || '',
      tax_bi_Id: entry?.tax_bi_Id || '',
      godownId: entry?.godownId || '',
      narr: entry?.narr || '',
      pcategoryId: entry?.pcategoryId || '',
      stockdetails: entry?.stockdetails || [
        {
          productId: null,
          acolourId: null,
          partno: '',
          chassisNo: '',
          enginNo: '',
          colourcode: '',
          acolour: null,
          keyNo: '',
          batteryNo: '',
          fscNo: '',
          rate: 0,
          product: null,
        },
        {
          productId: null,
          acolourId: null,
          partno: '',
          chassisNo: '',
          enginNo: '',
          colourcode: '',
          acolour: null,
          keyNo: '',
          batteryNo: '',
          fscNo: '',
          rate: 0,
          product: null,
        },
        {
          productId: null,
          acolourId: null,
          partno: '',
          chassisNo: '',
          enginNo: '',
          colourcode: '',
          acolour: null,
          keyNo: '',
          batteryNo: '',
          fscNo: '',
          rate: 0,
          product: null,
        },
        {
          productId: null,
          acolourId: null,
          partno: '',
          chassisNo: '',
          enginNo: '',
          colourcode: '',
          acolour: null,
          keyNo: '',
          batteryNo: '',
          fscNo: '',
          rate: 0,
          product: null,
        },
        {
          productId: null,
          acolourId: null,
          partno: '',
          chassisNo: '',
          enginNo: '',
          colourcode: '',
          acolour: null,
          keyNo: '',
          batteryNo: '',
          fscNo: '',
          rate: 0,
          product: null,
        },
      ],
      inv_details: entry?.inv_details || [
        {
          productId: null,
          partno: '',
          qty: 0,
          rate: 0,
          totalrate: 0,
          mrp: 0,
          amount: 0,
          dprice: 0,
          IGST: 0,
          SGST: 0,
          CGST: 0,
          gstamt: 0,
          product: null,
        },
        {
          productId: null,
          partno: '',
          qty: 0,
          rate: 0,
          totalrate: 0,
          mrp: 0,
          amount: 0,
          dprice: 0,
          IGST: 0,
          SGST: 0,
          CGST: 0,
          gstamt: 0,
          product: null,
        },
        {
          productId: null,
          partno: '',
          qty: 0,
          rate: 0,
          totalrate: 0,
          mrp: 0,
          amount: 0,
          dprice: 0,
          IGST: 0,
          SGST: 0,
          CGST: 0,
          gstamt: 0,
          product: null,
        },
        {
          productId: null,
          partno: '',
          qty: 0,
          rate: 0,
          totalrate: 0,
          mrp: 0,
          amount: 0,
          dprice: 0,
          IGST: 0,
          SGST: 0,
          CGST: 0,
          gstamt: 0,
          product: null,
        },
        {
          productId: null,
          partno: '',
          qty: 0,
          rate: 0,
          totalrate: 0,
          mrp: 0,
          amount: 0,
          dprice: 0,
          IGST: 0,
          SGST: 0,
          CGST: 0,
          gstamt: 0,
          product: null,
        },
      ],
      totalAmount: entry?.totalAmount || 0,
      entry_details: [],
    }),
    [entry]
  );
  const methods = useForm({
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    control,
    getValues,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const entrydetails = useFieldArray({
    control,
    name: 'entry_details',
  });
  const invdetails = useFieldArray({
    control,
    name: 'inv_details',
  });

  const defaultEntryDetails = useCallback(() => {
    const entry_details = filterTfields.map((x, index) => {
      const temp = { ...x };
      if (temp.name === "PARTY") {
        const totalWithDiscountRate = values.inv_details.reduce((a, b) => (a + (b.totalrate - (isNaN(parseFloat(b.dprice)) ? 0 : parseFloat(b.dprice)))), 0);
        temp.accountId = party.id;
        temp.amount = totalWithDiscountRate;
        temp.cb = temp.cd;
        // entrydetails.update(index, temp)
      } if (values.in_type &&
        filterTfields.filter(y =>
          (values.in_type === y.in_type)
        )
          .map(x => x.name)
          .includes(x.name)) {

        const totagstamt = values.inv_details.filter(x => x.productId).reduce((acc, next) => acc + next.gstamt, 0)
        if (['CGST', 'SGST'].includes(x.name)) {
          console.log("totagstamt", totagstamt)
          temp.amount = (totagstamt / 2)
        } else {
          temp.amount = totagstamt
        }
      }
      else {
        temp.amount = x.amount || 0;
        temp.cb = x.cd;
        // entrydetails.update(index, temp)
      }

      return temp;
    }).filter(y => !Array.isArray(y.tax_bi_Id) || (Array.isArray(y.tax_bi_Id) && (values.in_type === y.in_type)))
    return entry_details;
  }, [filterTfields, party.id, values.in_type, values.inv_details])




  useEffect(() => {
    // Simulate data loading
    const timer = setTimeout(() => {
      setisLoading(false); // Set to false when data or component is ready
    }, 1000);

    return () => clearTimeout(timer); // Cleanup timer
  }, []);
  useEffect(() => {

    if (filterTfields.length && entrydetails.fields.length && entry?.id && !entry._processed) {
      console.log('set form value')
      // Prepare stockdetails and inv_details transformations
      const stockdetails = entry.stockdetails.map((x) => ({
        ...x,
        rate: roundOff(x?.rate),
        partno: x?.product?.partno,
        colourcode: x?.acolour?.identity,
      }));

      const inv_details = entry.inv_details.map((x) => ({
        ...x,
        partno: x?.product?.partno,
        totalrate: roundOff((x.rate * x.qty)),
        gstamt: roundOff((((x.rate * x.qty) - x.dprice) / 100) * x.IGST),
        mrp: 0,
        netamt: roundOff(((x.rate * x.qty) - x.dprice))
      }));

      setTotalBillAmount(entry.amount)
      console.log('inv_details', inv_details)
      console.log('stockdetails', stockdetails)
      // Prepare mixarray based on entrydetails
      const mixarray = entrydetails.fields.map((detail, entrydetailindex) => {
        if (detail.order === 1 || detail.order === 2) {
          const index = entry.entry_details.findIndex((field) => field.order === detail.order);
          if (index !== -1) {
            return { ...detail, ...entry.entry_details[index] };
          }
        } else if (detail.order >= 3) {
          const findIndex = entry.entry_details.findIndex((field) => field.accountId === detail.accountId);
          if (findIndex !== -1) {
            return { ...detail, ...entry.entry_details[findIndex], };
          }
        }
        return detail;
      });
      console.log('entrydetails', mixarray)


      // Get current form state to avoid resetting arrays
      const currentState = getValues(); // react-hook-form method to get the current form values

      reset(
        {
          ...currentState, // Preserve the existing state
          ...entry, // Override only non-array fields
          stockdetails, // Explicitly update stockdetails
          entry_details: mixarray, // Explicitly update entry_details
          inv_details, // Explicitly update inv_details
          _processed: true, // Prevent further processing
        },
        { keepDefaultValues: true } // Ensures only updated fields are reset
      );

      setEntry((pre) => ({ ...pre, _processed: true }));
    }
  }, [entry, entrydetails.fields, filterTfields, getValues, reset, tfields]);


  const watchedInvDetails = watch('inv_details');
  const watchedEntryDetails = watch('entry_details');


  const totalWithDiscountRate = watchedInvDetails.filter(y => y.productId).reduce((a, b) => (a + roundOff(b.netamt)), 0);
  const [totalBillAmount, setTotalBillAmount] = useState(0);
  console.log("watchedInvDetails", watchedInvDetails.filter(y => y.productId))
  useEffect(() => {
    // const entry_details = filterTfields.map((x, index) => {
    //   const temp = { ...x };
    //   if (temp.name === "PARTY") {
    //     const totalWithDiscountRate = values.inv_details.reduce((a, b) => (a + (b.totalrate - (isNaN(parseFloat(b.dprice)) ? 0 : parseFloat(b.dprice)))), 0);
    //     temp.accountId = party.id;
    //     temp.amount = totalWithDiscountRate;
    //     temp.cb = temp.cd;
    //     // entrydetails.update(index, temp)
    //   } if (values.in_type &&
    //     filterTfields.filter(y =>
    //       (values.in_type === y.in_type)
    //     )
    //       .map(x => x.name)
    //       .includes(x.name)) {

    //     const totagstamt = values.inv_details.filter(x => x.productId).reduce((acc, next) => acc + next.gstamt, 0)
    //     if (['CGST', 'SGST'].includes(x.name)) {
    //       temp.amount = (totagstamt / 2)
    //     } else {
    //       temp.amount = totagstamt
    //     }
    //   }
    //   else {
    //     temp.amount = x.amount || 0;
    //     temp.cb = x.cd;
    //     // entrydetails.update(index, temp)
    //   }

    //   return temp;
    // })
    if (filterTfields.length && watchedEntryDetails.length === 0) {
      console.log("set default effect accounts from setting filtertfields", watchedEntryDetails)
      // console.log('entrydetails default fileds')
      entrydetails.replace(
        filterTfields.sort((a, b) => a - b).map(x => ({ ...x, amount: 0 }))
      );

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTfields, watchedEntryDetails])
  useEffect(() => {
    if (tfields.length && values.t_type && values.pcategoryId) {
      // console.log('useEffect 3', tfields)
      setFilterTfields(pre => tfields.filter(x => x.t_type === values.t_type && x.pcategoryId?.includes(values.pcategoryId)))
    }
  }, [tfields, values.pcategoryId, values.t_type])


  const onSubmit = handleSubmit(async (payload) => {
    try {
      payload = sanitizeComplexObject(payload);
      payload.amount = totalBillAmount;
      payload.inv_details = payload.inv_details.filter(x => x.productId)
      payload.stockdetails = payload.stockdetails.filter(x => x.productId)
      // payload.jobcardNo = jobcardNo;
      const URL = `automobile/entries/puchase/${values.pcategoryId}`;
      // const cd = payload.c_d;
      const resetValues = { ...values };
      // resetValues.c_d = cd;

      console.log('payload', payload)
      // Calculate `ct` (credit total)
      const ct = payload.entry_details
        .filter((x) => x.cd === 1)
        .reduce((pre, next) => pre + (next.amount || 0), 0)
        .toFixed(2);

      // Parse `ct` as a float
      const creditTotal = parseFloat(ct);

      // Calculate `dt` (debit total)
      const dt = payload.entry_details
        .filter((x) => x.cd === 2)
        .reduce((pre, next) => pre + (next.amount || 0), 0)
        .toFixed(2);

      // Parse `dt` as a float
      const debitTotal = parseFloat(dt);

      console.log('Credit Total:', creditTotal);
      console.log('Debit Total:', debitTotal);

      console.log(ct, dt)
      const { success, show } = await update({ url: URL, payload });
      if (success) {
        if (show) {
          enqueueSnackbar('success!');
        }
        // getAll();
        reset(resetValues);
        document.getElementById('invoice_type').focus();
      }
      // setImportDisabled(false);
    } catch (error) {
      reset(payload);
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });
  const handleSaveAsDraft = handleSubmit(async (data) => {
    loadingSave.onTrue();

    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      loadingSave.onFalse();
      router.push(paths.dashboard.invoice.root);
    } catch (error) {
      console.error(error);
      loadingSave.onFalse();
    }
  });

  const handleCreateAndSend = handleSubmit(async (data) => {
    loadingSend.onTrue();

    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      loadingSend.onFalse();
      router.push(paths.dashboard.invoice.root);
    } catch (error) {
      console.error(error);
      loadingSend.onFalse();
    }
  });

  const GetPartyData = useCallback(
    async (debouncedCode) => {
      try {
        setPartyLoading(false);
        setPartyInputValue(debouncedCode.toString())
        if (debouncedCode > 0) {
          setisLoading(true);
          const URL = `automobile/accounts/${debouncedCode}?column=identity&`;
          const { data } = await fetcher(URL);
          if (data?.id) {
            if (data?.booking) {
              setParty(data?.booking);

            } else {
              setParty({ ...data });

            }
          } else {
            enqueueSnackbar('Incorrect Code!', {
              variant: 'info',
            });
            setParty({});
          }
          setisLoading(false);
        } else {
          setParty({});
        }
        setPartyLoading(false)
        setisLoading(false);
      } catch (error) {
        console.error(error);
        setPartyLoading(false)
        setisLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [enqueueSnackbar]
  );
  const GetPartyDataList = useCallback(
    async (debouncedCode) => {
      try {
        setPartyLoading(false);

        if (debouncedCode > 0) {
          const URL = `automobile/accounts/${debouncedCode}?column=identity&multiple=true&`;
          const { data } = await fetcher(URL);
          if (Array.isArray(data))
            setPartyOptions(data || [])
          // else
          //   if (data?.id) {
          //     if (data?.booking) {
          //       setParty(data?.booking);
          //     } else {
          //       setParty({ ...data });
          //     }
          //   } else {
          //     enqueueSnackbar('Incorrect Code!', {
          //       variant: 'info',
          //     });
          //     setParty({});
          //   }
        }
        setPartyLoading(false)
      } catch (error) {
        console.error(error);
        setPartyLoading(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );
  const handleEntryDetailsChange = useCallback(
    async (event, rowId, field) => {
      const value = isNaN(parseFloat(event.target.value)) ? '' : parseFloat(event.target.value);
      const findIndex = entrydetails.fields.findIndex(x => x.accountId === field)
      let defaultcd = 1;
      let defaultoperation = 'ADD';
      if (entrydetails.fields[findIndex].order === 1 || entrydetails.fields[findIndex].order === 2) {
        const index = filterTfields.findIndex(x => x.order === entrydetails.fields[findIndex].order);
        if (index !== -1) {
          defaultcd = filterTfields[index].cd;
          defaultoperation = filterTfields[index].operation;
        }
      } else if (entrydetails.fields[findIndex].order >= 3) {
        const index = filterTfields.findIndex(x => x.accountId === entrydetails.fields[findIndex].accountId);
        if (index !== -1) {
          defaultcd = filterTfields[index].cd;
          defaultoperation = filterTfields[index].operation;
        }

      }

      // Update `cd` based on the value
      // eslint-disable-next-line no-nested-ternary
      const cd = value >= 0 ? defaultcd : (defaultcd === 1 ? 2 : 1);

      // Update the entry details
      entrydetails.update(findIndex, {
        ...entrydetails.fields[findIndex],
        amount: value,
        cd, // Set the updated `cd`
        cb: cd, // Set the updated `cd`
      });

      // Adjust `party a/c` if `order >= 3`
      if (entrydetails.fields[findIndex].order >= 3) {
        const allinv = watchedInvDetails.filter(z => z.productId)
        const totalinvoice = {
          productId: null,
          partno: '',
          qty: allinv.reduce((a, b) => a + b.qty, 0),
          rate: allinv.reduce((a, b) => a + b.rate, 0),
          mrp: allinv.reduce((a, b) => a + b.mrp, 0),
          amount: allinv.reduce((a, b) => a + b.amount, 0),
          dprice: 0,
          gstamt: allinv.reduce((a, b) => a + b.gstamt, 0),

          netamt: allinv.reduce((a, b) => a + b.netamt, 0),
          totalrate: allinv.reduce((a, b) => a + b.totalrate, 0)
        }
        const partyIndex = entrydetails.fields.findIndex(x => x.order === 1); // Find `party a/c`
        if (partyIndex !== -1) {
          const partyField = entrydetails.fields[partyIndex];
          const totalBillAmount = (totalinvoice.netamt + totalinvoice.gstamt)
          // Adjust `party a/c` amount based on the value
          const partyAmount =
            totalBillAmount + value; // Debit for negative value
          // Update the `party a/c` field
          console.log(defaultcd, entrydetails.fields[findIndex].cd, totalBillAmount, value, partyAmount)
          if (!isNaN(partyAmount))
            entrydetails.update(partyIndex, {
              ...partyField,
              amount: partyAmount,
            });
          setTotalBillAmount(partyAmount)

        }
      }

    },
    [entrydetails, filterTfields, watchedInvDetails]
  );

  const setPartyAccount = useCallback((party) => {
    if (entrydetails.fields.length > 0) {
      const partyIndex = entrydetails.fields.findIndex(x => x.order === 1)
      console.log("partyIndex", partyIndex)
      if (entrydetails.fields[partyIndex]?.accountId !== party.id)
        entrydetails.update(partyIndex, { ...entrydetails.fields[partyIndex], accountId: party.id })
    }
  }, [entrydetails])

  useEffect(() => {
    if (party?.id)
      setPartyAccount(party);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [party])

  const descriptionElementRef = useRef(null);
  const uploadFile = async (data) => {
    try {
      const URL = 'automobile/stockdetails/import/';
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const formdata = new FormData();
      formdata.append('file', data);
      const response = await create({ url: URL, payload: formdata, config });
      if (response) {
        setValue('stockdetails', response?.stockdetails);
        setValue('truckNo', response?.truckNo);
        setValue('transportName', response?.transportName);
        setValue('bill_no', response?.bill_no);
        setValue('b_date', new Date(response?.b_date));
        setValue('LRefNo', response?.LRefNo);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderTotal = (
    <Stack
      spacing={1}
      alignItems="flex-end"
      sx={{ mt: 3, textAlign: 'right', typography: 'body2' }}
    >
      <Stack direction="row">
        <Box sx={{ color: 'text.secondary' }}>Subtotal</Box>
        <Box sx={{ width: 160, typography: 'subtitle2' }}>{formatCurrency(totalWithDiscountRate) || '-'}</Box>
      </Stack>
      {
        values.entry_details?.map((x, index) => x.order > 0 ? <Stack direction="row" display={!x.ishidden && (x.tax_bi_Id?.length === 0 || x.in_type === values.in_type) ? 'flex' : 'none'}>
          <Box sx={{ color: 'text.secondary' }}>{x.name} </Box>
          <Box sx={{ width: 50, color: 'text.secondary' }}> {x.cd === 1 ? 'CR' : 'DB'}</Box>
          <Box sx={{ width: 160, typography: 'subtitle2' }}>
            {
              x.isReadOnly === "YES" ? values.entry_details[index].amount : <RHFTextField
                size="small"
                name={`entry_details[${index}].accountId`}
                type="number"
                sx={{ maxWidth: { md: 120 }, textAlign: 'right' }}
                value={values.entry_details[index].amount}
                onChange={(event) => handleEntryDetailsChange(event, index, values.entry_details[index].accountId)}
                inputProps={{
                  readonly: x.isReadOnly === "YES",
                  style: { textAlign: 'right' } // Align text to the right
                }}
                disabled={x.isReadOnly === "YES"}
              />
            }

          </Box>
        </Stack> : <></>)
      }
      {/* {
        values.entry_details?.map((x, index) => x.order > 2 ? <Stack direction="row" display={x.tax_bi_Id?.length === 0 || x.in_type === values.in_type ? 'flex' : 'none'}>
          <Box sx={{ color: 'text.secondary' }}>{x.name}</Box>
          <Box sx={{ width: 160, typography: 'subtitle2' }}>
            <RHFTextField
              size="small"
              name={`entry_details[${index}].accountId`}
              type="number"
              sx={{ maxWidth: { md: 120 } }}
              value={values.entry_details[index].amount}
              onChange={(event) => handleEntryDetailsChange(event, index, values.entry_details[index].accountId)}
              inputProps={{
                readonly: x.isReadOnly === "YES"
              }}
              disabled={x.isReadOnly === "YES"}
            />
          </Box>
        </Stack> : <></>)
      } */}

      <Stack direction="row" sx={{ typography: 'subtitle1' }}>
        <Box>Total</Box>
        <Box sx={{ width: 160, typography: 'subtitle2' }}>{formatCurrency(totalBillAmount) || '-'}</Box>
      </Stack>
    </Stack>
  );
  useEffect(() => {
    if (party?.gst_no || party?.stateId) {
      console.log('set RD, URD')

      const CompanyGSTNO = user?.companies?.find((x) => x.id === user?.companyId)?.gst_no;
      const PartyGSTNO = party?.gst_no;
      const companyStateId = user?.companies?.find((x) => x.id === user?.companyId)?.stateId;
      const partyStateId = party?.stateId;
      if (CompanyGSTNO && PartyGSTNO) {
        const GST_Company = CompanyGSTNO?.substring(0, 2);
        const GST_PARTY = PartyGSTNO?.substring(0, 2);
        setValue('tax_bi_Id', 1); // RD
        if (GST_Company === GST_PARTY) {
          setValue('in_type', 1); // local
        }
        else {
          setValue('in_type', 2); // Inter
        }
      } else if (companyStateId && partyStateId) {
        setValue('tax_bi_Id', 2); // URD
        if (companyStateId === partyStateId) {
          setValue('in_type', 1); // local
        } else {
          setValue('in_type', 2); // Inter
        }
      }
    }
  }, [setValue, user?.companies, user?.companyId, party?.gst_no, party?.stateId]);

  const GETBYID = useCallback(async (id) => {
    try {
      let URL = `automobile/entries/${id}`;
      URL += `?status=true&`;
      const { data } = await fetcher(URL);
      if (data) {
        const addoppsiteaccount = data?.entry_details?.find(x => x.order === 2)?.account;
        const addpartyaccount = data?.entry_details?.find(x => x.order === 1)?.account;
        setEntry({ ...data, _processed: false, });
        setFilterTfields(tfields.filter(x => x.t_type === data.t_type && x.pcategoryId?.includes(data.pcategoryId)))
        if (addpartyaccount) {
          setParty(addpartyaccount);
          setPartyCode(addpartyaccount.identity)
        }
      }
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tfields]);

  useEffect(() => {
    if (currentInvoice?.id) {
      console.log('get invoice data')
      console.log(currentInvoice?.id)

      GETBYID(currentInvoice?.id);
    }
  }, [GETBYID, currentInvoice])

  // useEffect(() => {

  //   if (create_edit.value) {
  //     console.log('useEffect 7')
  //     const { current: descriptionElement } = descriptionElementRef;
  //     if (descriptionElement) {
  //       descriptionElement.focus();
  //     }
  //   }
  // }, [create_edit.value]);

  const debouncedCode = useDebounce(partyInputValue, 500);

  useEffect(() => {

    if (debouncedCode.length !== 0 && debouncedCode.length > 6 && (debouncedCode !== party?.identity)) {

      GetPartyDataList(debouncedCode);
    }
  }, [GetPartyData, GetPartyDataList, debouncedCode, party?.identity, setValue]);

  useEffect(() => {
    GetPartyData(partyCode)
  }, [GetPartyData, partyCode])

  const revalidate = useCallback((stockdetails) => {
    // Group stockdetails by partno and calculate totals for each group
    // const groupedStockDetails = stockdetails?.reduce((acc, item) => {
    //   if (!item.partno) return acc;
    //   const partno = item.partno;

    //   if (!acc[partno]) {
    //     acc[partno] = {
    //       qty: 0,
    //       rate: 0,
    //       mrp: 0,
    //       amount: 0,
    //       IGST: 0,
    //       SGST: 0,
    //       CGST: 0,
    //       gstamt: 0,
    //       product: item.product,
    //       productId: item.productId,
    //     };
    //   }

    //   if (item.product) {

    //     acc[partno].qty += 1; // Assuming each item in stockdetails has a qty of 1
    //     acc[partno].mrp += parseFloat(item.product ? item.product.sal_rate : item.rate);
    //     acc[partno].IGST = item.product.IGST || 0;
    //     acc[partno].SGST = item.product.SGST || 0;
    //     acc[partno].CGST = item.product.CGST || 0;
    //     const gstAmount = (acc[partno].mrp / (100 + acc[partno].IGST)) * acc[partno].IGST;
    //     acc[partno].gstamt += gstAmount;
    //     acc[partno].amount = acc[partno].mrp - gstAmount;
    //   }

    //   return acc;
    // }, {});

    // if (groupedStockDetails) {
    //   Object.entries(groupedStockDetails).forEach(([partno, details], idx) => {
    //     const totalrate = stockdetails?.filter(x => x.partno === partno)?.reduce((pre, next) => pre + parseFloat(next.rate), 0);
    //     if (values.inv_details[idx]) {
    //       // Update existing index in inv_details
    //       setValue(`inv_details[${idx}].productId`, details.productId);
    //       setValue(`inv_details[${idx}].partno`, partno);
    //       setValue(`inv_details[${idx}].qty`, details.qty);
    //       setValue(`inv_details[${idx}].rate`, (totalrate / details.qty));
    //       setValue(`inv_details[${idx}].totalrate`, totalrate);
    //       setValue(`inv_details[${idx}].mrp`, details.mrp);
    //       setValue(`inv_details[${idx}].amount`, details.amount);
    //       setValue(`inv_details[${idx}].IGST`, details.IGST);
    //       setValue(`inv_details[${idx}].SGST`, details.SGST);
    //       setValue(`inv_details[${idx}].CGST`, details.CGST);
    //       setValue(`inv_details[${idx}].gstamt`, ((totalrate / 100) * details.IGST));
    //       setValue(`inv_details[${idx}].product`, details.product);
    //     } else {
    //       // Add new entry if index doesn't exist
    //       setValue(`inv_details[${idx}]`, {
    //         productId: details.productId,
    //         partno,
    //         qty: details.qty,
    //         rate: (totalrate / details.qty),
    //         totalrate,
    //         mrp: details.mrp,
    //         amount: details.amount,
    //         IGST: details.IGST,
    //         SGST: details.SGST,
    //         CGST: details.CGST,
    //         gstamt: ((totalrate / 100) * details.IGST),
    //         product: details.product,
    //       });
    //     }
    //   });

    // }

    const groupedStockDetails = stockdetails?.reduce((acc, item) => {
      if (!item.partno) return acc;
      const partno = item.partno;

      if (!acc[partno]) {
        acc[partno] = {
          qty: 0,
          rate: 0,
          mrp: 0,
          amount: 0,
          IGST: 0,
          SGST: 0,
          CGST: 0,
          gstamt: 0,
          netamt: 0,
          totalrate: 0,
          dprice: 0,
          product: item.product,
          productId: item.productId,
        };
      }

      if (item.product) {
        acc[partno].qty += 1; // Assuming each item in stockdetails has a qty of 1
        // acc[partno].mrp += parseFloat(item.mrp);
        acc[partno].IGST = item.product.IGST || 0;
        acc[partno].SGST = item.product.SGST || 0;
        acc[partno].CGST = item.product.CGST || 0;
        // const gstAmount = (acc[partno].mrp / (100 + acc[partno].IGST)) * acc[partno].IGST;
        acc[partno].rate = isNaN(parseFloat(item.rate)) ? 0 : parseFloat(item.rate);

      }

      return acc;
    }, {});

    // Only update if groupedStockDetails is different
    if (groupedStockDetails) {
      const updatedInvDetails = []; // Clone current inv_details

      Object.entries(groupedStockDetails).forEach(([partno, details], idx) => {

        if (invdetails.fields?.[idx]?.dprice)
          details.dprice = invdetails.fields[idx].dprice;
        else details.dprice = 0;

        details.totalrate = roundOff((details.rate * details.qty));

        details.netamt = details.totalrate - details.dprice;
        details.gstamt = roundOff((details.netamt / 100) * details.IGST);

        const newDetail = {
          productId: details.productId,
          partno,
          qty: details.qty,
          rate: details.rate,
          mrp: details.mrp,
          amount: details.amount,
          IGST: details.IGST,
          SGST: details.SGST,
          CGST: details.CGST,
          gstamt: details.gstamt,
          product: details.product,
          netamt: details.netamt,
          totalrate: details.totalrate
        };

        if (updatedInvDetails[idx]) {
          // Update existing index
          updatedInvDetails[idx] = { ...updatedInvDetails[idx], ...newDetail };
        } else {
          // Add new entry if index doesn't exist
          updatedInvDetails[idx] = newDetail;
        }
      });

      console.log("updatedInvDetails", updatedInvDetails)
      // updatedInvDetails.push({
      //   productId: null,
      //   partno: '',
      //   qty: updatedInvDetails.reduce((a, b) => a + b.qty, 0),
      //   rate: updatedInvDetails.reduce((a, b) => a + b.rate, 0),
      //   mrp: updatedInvDetails.reduce((a, b) => a + b.mrp, 0),
      //   amount: updatedInvDetails.reduce((a, b) => a + b.amount, 0),
      //   dprice: 0,
      //   gstamt: updatedInvDetails.reduce((a, b) => a + b.gstamt, 0),
      //   netamt: updatedInvDetails.reduce((a, b) => a + b.netamt, 0),
      //   totalrate: updatedInvDetails.reduce((a, b) => a + b.totalrate, 0)
      // })

      // Compare old and new arrays before updating
      if (JSON.stringify(invdetails.fields) !== JSON.stringify(updatedInvDetails)) {
        invdetails.replace(updatedInvDetails);
        entryde()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invdetails])



  const entryde = useCallback(() => {
    const allinv = watchedInvDetails.filter(z => z.productId)
    if (allinv.length) {
      const totalinvoice = {
        productId: null,
        partno: '',
        qty: allinv.reduce((a, b) => a + b.qty, 0),
        rate: allinv.reduce((a, b) => a + b.rate, 0),
        mrp: allinv.reduce((a, b) => a + b.mrp, 0),
        amount: allinv.reduce((a, b) => a + b.amount, 0),
        dprice: 0,
        gstamt: allinv.reduce((a, b) => a + b.gstamt, 0),

        netamt: allinv.reduce((a, b) => a + b.netamt, 0),
        totalrate: allinv.reduce((a, b) => a + b.totalrate, 0)
      }
      entrydetails.fields.forEach((y, findIndex) => {
        if (entrydetails.fields[findIndex].order === 1) {
          const amt = totalinvoice.netamt + totalinvoice.gstamt
          console.log('amt', amt)
          entrydetails.update(findIndex, { ...entrydetails.fields[findIndex], amount: parseFloat(amt.toFixed(2)) })
        } if (entrydetails.fields[findIndex].order === 2) {
          if (values.in_type) {
            const amt = (totalinvoice.netamt)
            entrydetails.update(findIndex, { ...entrydetails.fields[findIndex], amount: parseFloat(amt.toFixed(2)) })
          } else {
            const amt = (totalinvoice.netamt) + totalinvoice.gstamt
            entrydetails.update(findIndex, { ...entrydetails.fields[findIndex], amount: parseFloat(amt.toFixed(2)) })
          }
        } if (entrydetails.fields[findIndex].name === 'CGST' && (entrydetails.fields[findIndex].tax_bi_Id?.length === 0 || entrydetails.fields[findIndex].in_type === values.in_type)) {
          console.log("CGST")
          entrydetails.update(findIndex, { ...entrydetails.fields[findIndex], amount: parseFloat((totalinvoice.gstamt / 2).toFixed(2)) })
          const igstindex = entrydetails.fields.findIndex(y => y.name === 'IGST')
          entrydetails.update(igstindex, { ...entrydetails.fields[igstindex], amount: 0 })
        } if (entrydetails.fields[findIndex].name === 'SGST' && (entrydetails.fields[findIndex].tax_bi_Id?.length === 0 || entrydetails.fields[findIndex].in_type === values.in_type)) {
          console.log("SGST")

          const igstindex = entrydetails.fields.findIndex(y => y.name === 'IGST')
          entrydetails.update(igstindex, { ...entrydetails.fields[igstindex], amount: 0 })
          entrydetails.update(findIndex, { ...entrydetails.fields[findIndex], amount: parseFloat((totalinvoice.gstamt / 2).toFixed(2)) })
        } if (entrydetails.fields[findIndex].name === 'IGST' && (entrydetails.fields[findIndex].tax_bi_Id?.length === 0 || entrydetails.fields[findIndex].in_type === values.in_type)) {
          console.log("IGST")

          entrydetails.update(findIndex, { ...entrydetails.fields[findIndex], amount: parseFloat((totalinvoice.gstamt).toFixed(2)) })
          const sgstindex = entrydetails.fields.findIndex(y => y.name === 'SGST')
          const cgstindex = entrydetails.fields.findIndex(y => y.name === 'CGST')
          entrydetails.update(sgstindex, { ...entrydetails.fields[sgstindex], amount: 0 })
          entrydetails.update(cgstindex, { ...entrydetails.fields[cgstindex], amount: 0 })
        }

      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedInvDetails, values.in_type])

  useEffect(() => {
    if (values.in_type && watchedInvDetails.filter(x => x.productId).length) {

      entryde()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.in_type, watchedInvDetails])
  // useEffect(() => {
  //   console.log('useEffect 9')
  //   const allinv = watchedInvDetails.filter(z => z.productId)
  //   const totalinvoice = {
  //     productId: null,
  //     partno: '',
  //     qty: allinv.reduce((a, b) => a + b.qty, 0),
  //     rate: allinv.reduce((a, b) => a + b.rate, 0),
  //     mrp: allinv.reduce((a, b) => a + b.mrp, 0),
  //     amount: allinv.reduce((a, b) => a + b.amount, 0),
  //     dprice: 0,
  //     gstamt: allinv.reduce((a, b) => a + b.gstamt, 0),

  //     netamt: allinv.reduce((a, b) => a + b.netamt, 0),
  //     totalrate: allinv.reduce((a, b) => a + b.totalrate, 0)
  //   }
  //   entrydetails.fields.forEach((y, findIndex) => {
  //     if (entrydetails.fields[findIndex].order === 1) {
  //       if (values.in_type) {
  //         const amt = (totalinvoice.totalrate - totalinvoice.dprice)
  //         entrydetails.update(findIndex, { ...entrydetails.fields[findIndex], amount: amt })
  //       } else {
  //         const amt = (totalinvoice.totalrate - totalinvoice.dprice) + totalinvoice.gstamt
  //         entrydetails.update(findIndex, { ...entrydetails.fields[findIndex], amount: amt })
  //       }
  //     } if (entrydetails.fields[findIndex].order === 2) {
  //       const amt = (totalinvoice.totalrate - totalinvoice.dprice) + totalinvoice.gstamt
  //       entrydetails.update(findIndex, { ...entrydetails.fields[findIndex], amount: amt })
  //     } if (entrydetails.fields[findIndex].name === 'CGST') {
  //       entrydetails.update(findIndex, { ...entrydetails.fields[findIndex], amount: totalinvoice.gstamt / 2 })
  //     } if (entrydetails.fields[findIndex].name === 'SGST') {
  //       entrydetails.update(findIndex, { ...entrydetails.fields[findIndex], amount: totalinvoice.gstamt / 2 })
  //     } if (entrydetails.fields[findIndex].name === 'IGST') {
  //       entrydetails.update(findIndex, { ...entrydetails.fields[findIndex], amount: totalinvoice.gstamt })
  //     }

  //   })
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [watchedInvDetails, values.in_type])




  return (
    <FormProvider methods={methods}>
      <input
        ref={fileRef}
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv"
        style={{ display: 'none' }}
        multiple={false}
        onChange={async (e) => {
          if (e.target.files['0']) {
            await uploadFile(e.target.files['0']);
            e.target.value = '';
          }
        }}
      />
      <Dialog fullScreen TransitionComponent={Transition} open={create_edit.value} onClose={create_edit.onFalse} scroll="paper">
        <DialogTitle sx={{ pb: 2 }}>
          <Stack justifyContent="space-between" direction="row" spacing={2} sx={{ mt: 0 }}>
            <div>
              Add
            </div>

            <div>
              <Stack direction="row" rowGap={3} columnGap={3}>
                <div>
                  {
                    getCommonField('import', values)?.show && <Grid xs={6} sm={3} md={1} lg={1} xl={1} xxl={1} display="flex" justifyContent={{ xs: 'center', lg: 'end' }} alignItems="center">
                      <Button
                        sx={{ p: 0, minWidth: 'auto' }}
                        onClick={() => {
                          handleAttach();
                        }}
                      >
                        <Iconify icon="solar:import-bold" />
                      </Button>
                    </Grid>
                  }
                </div>
                <div>
                  {
                    values.t_type &&
                    <Iconify icon="vscode-icons:file-type-config" onClick={invsetting.onTrue} />
                  }
                </div>
              </Stack>
            </div>
          </Stack>
        </DialogTitle>
        {isLoading && (
          <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}>
            <CircularProgress />
          </Backdrop>
        )}
        <DialogContent dividers >
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={3} id="entertotab">
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <InvoiceNewEditStatusDate partyOptions={partyOptions} partyCode={partyCode} party={party} partyLoading={partyLoading} partyInputValue={partyInputValue} setPartyInputValue={setPartyInputValue} setPartyCode={setPartyCode} commonfield={commonfield} getCommonField={getCommonField} goDownData={goDownData} productcategories={productcategories} handleAttach={handleAttach} revalidate={revalidate} />
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={8} xl={8} rowSpacing={2} >
              {
                getCommonField('stockdetails', values)?.show && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InvoiceStockDetails revalidate={revalidate} />
                </Grid>
              }
              {
                getCommonField('inv_details', values)?.show && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InvoiceNewEditDetails filterTfields={filterTfields} revalidate={revalidate} />
                </Grid>
              }

              <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} rowSpacing={2} >
                <Grid item Container xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                  <Typography variant="subtitle2" sx={{ ml: '10px' }}>
                    Narration
                  </Typography>
                  <RHFTextField id="narration-field" name="narr" size="small" multiline rows={3} />
                </Grid>
                <Grid item Container xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                  {renderTotal}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Stack
            direction="row-reverse"
            alignItems="center"
            sx={{
              bottom: 0,
              // zIndex: 111111,
              p: 2,
              position: 'fixed',
              justifyContent: 'space-between',
              width: '100%',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
            id="modelfooter"
          >
            <Tooltip title="(Alt + A)" arrow>
              <LoadingButton
                type="button"
                variant="contained"
                loading={isSubmitting}
                onClick={async () => {
                  console.log(values)
                  await onSubmit(values);
                }}
              >
                {values.iscreate ? 'Add' : 'Update'}
              </LoadingButton>
            </Tooltip>

            <Tooltip title="(Esc)" arrow>
              <Button variant="outlined" onClick={create_edit.onFalse} sx={{ mr: 1 }}>
                Cancel
              </Button>
            </Tooltip>

            <Button
              variant="soft"
              onClick={() => {
                reset(values);
                document.getElementById('invoice_type').focus();
              }}
              sx={{ mr: 2 }}
            >
              Reset
            </Button>

            <Stack direction="row" alignItems="center" flexGrow={1} spacing={2} />
          </Stack>
          {/* <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ mt: 0 }}>
            <LoadingButton
              color="inherit"
              size="large"
              variant="outlined"
              loading={loadingSave.value && isSubmitting}
              onClick={handleSaveAsDraft}
            >
              Save as Draft
            </LoadingButton>

            <LoadingButton
              size="large"
              variant="contained"
              loading={loadingSend.value && isSubmitting}
              onClick={handleCreateAndSend}
            >
              {currentInvoice ? 'Update' : 'Create'} & Send
            </LoadingButton>
          </Stack> */}
        </DialogActions>
      </Dialog>
    </FormProvider>

  );
}

InvoiceNewEditForm.propTypes = {
  invsetting: PropTypes.object,
  currentInvoice: PropTypes.object,
  create_edit: PropTypes.object,
  data: PropTypes.object,
  tfields: PropTypes.array,
  goDownData: PropTypes.array,
};
