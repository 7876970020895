/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/prop-types */
import isEqual from 'lodash/isEqual';
import { useState, useCallback, useEffect, useContext, useMemo, useRef } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
} from 'material-react-table';
import RefreshIcon from '@mui/icons-material/Refresh';
import moment from 'moment';
import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
  Typography,
  lighten,
  Table,
  Container,
  TableBody,
  TableContainer,
  Tab,
  Card,
  Tabs,
  alpha,
  Tooltip,
  IconButton,
} from '@mui/material';


import { AccountCircle, Send, Print } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import queryString from 'query-string';
import { useSnackbar } from 'src/components/snackbar';
import { useDebounce } from 'src/hooks/use-debounce';
import {
  useTable,
  getComparator,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import { fetcher, endpoints, destroy } from 'src/utils/axios';
import { useEventListener } from 'src/hooks/use-event-listener';
import { stringToFunction, TableRowsLoader, UPermission } from 'src/_mock/constant_funcation';

import useSWR, { mutate } from 'swr';
import { AuthContext } from 'src/auth/context/jwt';
import { isNull } from 'lodash';
// Function to convert stringified function to actual function


// ----------------------------------------------------------------------

const STATUS_OPTIONS = [{ value: 'all', label: 'All' }];

const TABLE_HEAD = [
  { id: 'no', label: 'No.', width: 90 },
  { id: 't_date', label: 'Voucher Date' },
  { id: 'id', label: 'Voucher No.' },
  { id: 'cb', label: 'Cash/Debit' },
  { id: 'account', label: 'Party Name' },
  { id: 'amount', label: 'Bill Amount', width: 150 },
  { id: '', label: 'Action', width: 90 },
];
// ----------------------------------------------------------------------

export default function ReactMaterialTable({ url, componentName, QuickEditForm, urlparm }) {
  const location = useLocation();
  const navigate = useNavigate();
  const permission = UPermission('component', componentName);
  const [columns, setColumns] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnFilterFns, setColumnFnsFilters] = useState({});
  const [columnVisibility, setColumnVisibility] = useState(localStorage.getItem(`${componentName}_columnvisibility`) ? JSON.parse(localStorage.getItem(`${componentName}_columnvisibility`)) : {});
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [editRow, setEditRow] = useState(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    if (location.search) {
      const params = queryString.parse(location.search);
      const pageIndex = parseInt(params.page, 10) - 1 || 0;
      const pageSize = parseInt(params.limit, 10) || 10;
      const filters = params.filters ? JSON.parse(params.filters) : [];
      const global = params.globalFilter || '';
      const sort = params.sorting ? JSON.parse(params.sorting) : [];
      const columnFilterFns = params.columnFilterFns ? JSON.parse(params.columnFilterFns) : {};
      setPagination({ pageIndex, pageSize });
      setColumnFilters(filters);
      setGlobalFilter(global);
      setSorting(sort);
      setColumnFnsFilters(columnFilterFns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  // Optimized getUrl using useCallback with correct dependencies
  const getUrl = useCallback(() => {
    const params = {
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      filters: JSON.stringify(columnFilters?.filter(x => {
        if (Array.isArray(x.value)) {
          x.value = x.value.filter(x => !["", null, undefined].includes(x))
          if (x.value.length === 0)
            return false
          return true
        }
        return true;

      })),
      globalFilter,
      sorting: JSON.stringify(sorting),
      columnFilterFns: JSON.stringify(columnFilterFns),
      ...urlparm
    };
    return `${url}${queryString.stringify(params)}`;
  }, [pagination.pageIndex, pagination.pageSize, columnFilters, globalFilter, sorting, columnFilterFns, urlparm, url]);

  const getUrlParams = () => queryString.stringify({
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    filters: JSON.stringify(columnFilters),
    columnFilterFns: JSON.stringify(columnFilterFns),
    globalFilter,
    sorting: JSON.stringify(sorting),
    ...urlparm
  });

  const currentUrlParams = getUrlParams();
  // Update URL when state changes
  useEffect(() => {
    if (location.search !== `?${currentUrlParams}`) {
      navigate(`?${currentUrlParams}`, { replace: true });
    }
  }, [navigate, currentUrlParams, location.search]);
  const { data, error, isValidating, isLoading } = useSWR(getUrl(), fetcher, {
    revalidateOnFocus: false,
    shouldRetryOnError: true,
    refreshInterval: 60000
  });

  // const table = useTable();
  const { enqueueSnackbar } = useSnackbar();
  const settings = useSettingsContext();
  const { user } = useContext(AuthContext);

  const tableData = data?.data?.rows || [];

  const count = data?.data?.count || 0
  useEffect(() => {
    localStorage.setItem(`${componentName}_columnvisibility`, JSON.stringify(columnVisibility))
  }, [columnVisibility, componentName])
  // Function to create column definitions dynamically based on the configuration
  const createColumns = (config) => config.map((col) => {
    const column = {
      ...col,
      accessorFn: stringToFunction(col.accessor)
    };

    switch (col.datatype) {
      case 'text':
        column.Cell = ({ renderedCellValue }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <span>{renderedCellValue}</span>
          </Box>
        );
        break;
      case 'integer':
        column.Cell = ({ renderedCellValue }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <span>{renderedCellValue}</span>
          </Box>
        );
        break;
      case 'decimal':
        column.Cell = ({ renderedCellValue }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <span>{renderedCellValue}</span>
          </Box>
        );
        break;
      case 'currency':
        column.Cell = ({ cell }) => (
          <><Box
            component="span"
            sx={(theme) => ({
              backgroundColor:
                [1, 3, "1", "3"].includes(cell.row.original.t_type)
                  ? theme.palette.error.dark : theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </Box> {[1, 3, "1", "3"].includes(cell.row.original.t_type) ? ' DB' : ' CR'}</>
        );
        break;

      case 'date':
        column.accessorFn = (originalRow) => new Date(originalRow[col.accessorKey]);
        // eslint-disable-next-line no-unused-expressions
        column.Cell = ({ cell }) => cell.getValue().toLocaleDateString();
        column.sortingFn = 'datetime';
        column.filterFn = 'between';
        column.filterVariant = 'date-range';
        column.Header = ({ column }) => <em>{column.columnDef.header}</em>;
        column.muiFilterTextFieldProps = {
          sx: {
            minWidth: '250px',
          },
        };
        break;

      // Add more cases for other datatypes as needed
      default:
        break;
    }

    return column;
  });

  // Usage of useMemo with the dynamic column generator
  useEffect(() => {
    const columnConfig = data?.data?.table?.columnConfig || [];
    if (columns.length === 0) {
      setColumns(createColumns(columnConfig));
    }
  }, [columnFilterFns, columns.length, data?.data?.table?.columnConfig, data?.data?.table?.columnFilterFns]);

  const table = useMaterialReactTable({
    columns,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    data: tableData,
    enableColumnResizing: false,
    enableColumnFilterModes: true,
    enableColumnOrdering: false,
    enableGrouping: false,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowActions: false,
    enableRowSelection: false,
    enableDensityToggle: true,
    enableFilterMatchHighlighting: true,
    enableFullScreenToggle: true,
    enableGlobalFilterModes: true,
    enableGlobalFilterRankedResults: true,
    enableFilters: true,
    enableColumnFilters: true,
    columnFilterDisplayMode: 'popover',
    rowNumberDisplayMode: 'original',
    initialState: {
      showColumnFilters: false,
      showGlobalFilter: true,
      density: 'compact',

    },
    state: {
      columnFilterFns,
      showProgressBars: isValidating,
      pagination,
      sorting,
      globalFilter,
      columnFilters,
      isLoading,
      showAlertBanner: !!error,
      columnVisibility
    },
    onColumnVisibilityChange: setColumnVisibility,
    muiSkeletonProps: {
      animation: 'pulse',
      height: 28,
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    rowCount: count,
    muiTableToolbarTopProps: {
      sx: {
        '& button[aria-label="Show/Hide filters"] .MuiSvgIcon-root': {
          color: "#0958F0 !important"
        }
      },
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 15, 30, 50, 100],
      // shape: 'rounded',
      variant: 'outlined',
      sx: {
        button: {
          backgroundColor: '#ffffff',
          border: '1px solid #dcdcdc',
          borderRadius: '50px',
          padding: '8px 12px',
          margin: '0 4px',
          fontSize: '16px',
          color: '#333',
          cursor: 'pointer',
          transition: 'all 0.3s ease-in-out',
          boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 8px 20px rgba(0, 0, 0, 0.15)',
          },
          '&:active': {
            transform: 'translateY(2px)',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },
    muiSelectProps: {
      sx: {
        // Applies styles to rows per page dropdown
        backgroundColor: '#ffffff',
        border: '1px solid #dcdcdc',
        borderRadius: '5px',
        padding: '4px 8px',
        fontSize: '14px',
        color: '#333',
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out',
        boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: '0 8px 20px rgba(0, 0, 0, 0.15)',
        },
        '&:active': {
          transform: 'translateY(2px)',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    muiTableBodyCellProps: ({ column }) => ({
      // sx: {
      //   padding: '0px !important',
      // },
    }),
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        setEditRow(row.original);
        quickEdit.onTrue();
      },
      sx: {
        cursor: 'pointer',
      },
    }),
    muiTableContainerProps: {
      sx: { minHeight: '420px' }, // Prevents table from collapsing
    },
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    onColumnFilterFnsChange: setColumnFnsFilters,
    onSortingChange: setSorting,
    // icons: {
    //   DensitySmallIcon: (props) => <Iconify icon="solar:filter-bold" {...props} />,
    //   ArrowDownwardIcon: (props) => <Iconify icon="solar:filter-bold" {...props} />,
    //   SearchIcon: (props) => <Iconify icon="solar:filter-bold" {...props} />,
    //   SortIcon: (props) => (<Iconify icon="solar:round-sort-vertical-bold" {...props} />),

    // },

    renderTopToolbarCustomActions: () => (
      <div>
        {
          permission?.c && (
            <Tooltip title="(Alt + C)" arrow>
              {/* <Button
                variant="contained"
                sx={{ color: 'white !important' }}

              >
                
              </Button> */}

              <IconButton color="primary">
                <Iconify icon="gg:add-r" width="30" height="30" onClick={() => {
                  quickEdit.onTrue();
                }} style={{ fontSize: '30px' }} />
              </IconButton>
            </Tooltip>
          )
        }
        {/* <Button
          variant="contained"
          onClick={() => {
            // table.setCreatingRow(true);
            // table.setCreatingRow(
            //   createRow(table, {
            //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
            //   }),
            // );
          }}
        >
          Create New User
        </Button> */}
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => getAll()} color="success">
            <Iconify icon="ri:refresh-line" width="30" height="30" style={{ fontSize: '30px' }} />
          </IconButton>
          {/* <IconButton onClick={() => getAll()} color="success">
            <RefreshIcon />
          </IconButton> */}
        </Tooltip></div>
    ),
    renderRowActionMenuItems: ({ closeMenu }) => [
      <MenuItem
        key={0}
        onClick={() => {
          // View profile logic...
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        View Profile
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
          // Send email logic...
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <Send />
        </ListItemIcon>
        Send Email
      </MenuItem>,
    ],

  });
  // if (data?.data?.table?.columnVisibility)
  //   table.setColumnVisibility(data.data.table.stringToFunction)
  if (table.getState().isFullScreen && pagination.pageSize === 10) setPagination(pre => ({ ...pre, pageSize: 15 }))
  if (!table.getState().isFullScreen && pagination.pageSize === 15) setPagination(pre => ({ ...pre, pageSize: 10 }))

  const getAll = useCallback(() => {
    mutate(getUrl());
  }, [getUrl]);


  const quickEdit = useBoolean();

  useEffect(() => {
    if (quickEdit.value === false)
      setEditRow(null)
  }, [quickEdit.value])

  const handleKeyDown = (event) => {
    if (event.altKey && event.key.toLowerCase() === 'c') {
      event.stopPropagation();
      quickEdit.onTrue();
    }
    if (event.key === 'Escape') {
      table.setIsFullScreen(!table.getState().isFullScreen);
    }
    if (event.ctrlKey && event.key === 'r') {
      event.preventDefault();  // Prevent the default refresh action
      getAll();  // Call your custom function
    }
  };

  useEventListener('keydown', handleKeyDown);

  // Update URL when state changes
  useEffect(() => {
    const newUrlParams = queryString.stringify({
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      filters: JSON.stringify(columnFilters),
      globalFilter,
      sorting: JSON.stringify(sorting),
      isvoucher: true
    });
    navigate(`?${newUrlParams}`, { replace: true });
  }, [navigate, pagination, columnFilters, globalFilter, sorting]);
  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <Card sx={{ zIndex: table.getState().isFullScreen ? 1111 : undefined, }}>
        <MaterialReactTable table={table} muiTablePaperProps={({ table }) => ({
          style: {
            zIndex: table.getState().isFullScreen ? 333333 : undefined,
          }
        })}
        />
      </Card>
      {quickEdit.value && componentName === 'aMEntriesListPage' && (
        <QuickEditForm
          row={editRow || null}
          open={quickEdit.value}
          onClose={quickEdit.onFalse}
          getAll={getAll}
          tableData={tableData}
        />
      )}
      {quickEdit.value && componentName === 'commoninvoice' && (
        QuickEditForm({
          currentInvoice: editRow || null,
          create_edit: quickEdit,
          getAll,
          tableData
        })
      )}
    </Container>
  );
}
