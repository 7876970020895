import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { INVOICE_TYPEARRAY, KEY, PURCHASE_TYPE, TRANSACTION_TYPEARRAY } from 'src/_mock/constant';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'src/components/hook-form/form-provider';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { create, destroy, fetcher, update } from 'src/utils/axios';
import { useEventListener } from 'src/hooks/use-event-listener';
import { GroupFooter, GroupHeader, GroupItems } from 'src/_mock/constant_style';
import { DataGrid, GridDeleteIcon } from '@mui/x-data-grid';
import { useBoolean } from 'src/hooks/use-boolean';
import AUserQuickEditForm from 'src/sections/accounts/account/quick-edit-form';
import { sanitizeComplexObject, useBgColorAutocomplete } from 'src/_mock/constant_funcation';
import { RHFMultiSelect, RHFSelect, RHFTextField, RHFCheckbox } from 'src/components/hook-form';
import CompanyYear from './company-year';

export default function InvoiceSettings({ productcategories, open, onClose }) {
  const [loading, setLoading] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [accountEditList, setAccountEditList] = useState({});
  const [fieldName, setFieldName] = useState('');

  const bgColorAutocomplete = useBgColorAutocomplete();

  const values = useMemo(
    async () => ({
      accountCategory: 'TRADING',
      account: {},
      accountId: 0,
      accountImpact: 'YES',
      accountNature: 'Fixed',
      calculationMethod: 'Itemwise',
      isReadOnly: 'YES',
      name: "SGST",
      cd: "1",
      createdAt: "2024-06-06 16:42:50",
      id: 0,
      label: "",
      operation: null,
      order: 1,
      suborder: 1,
      status: true,
      t_type: 8,
      ishidden: false,
      in_type: '',
      tax_bi_Id: [],
      tsettingId: 1,
      updatedAt: "2024-08-15 10:50:19",
      valueTypes: null
    }),
    []
  );

  const methods = useForm({
    defaultValues: values,
  });

  const {
    reset,
    setValue,
    watch,
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = methods;

  const allValues = watch();
  const quickEditAccount = useBoolean();
  const confirm = useBoolean()

  const [rows, setRows] = useState([]);
  const options = {
    accountCategories: ["TRADING", "EXPENSE", "TAX"],
    operations: ["Add", "Subtract"],
    accountImpact: ["YES", "NO", "SEPARATE"],
    accountNature: ["Fixed", "Variable", "Affected A/C."],
    isReadOnly: ["YES", "NO"],
    calculationMethod: ["Itemwise", "Fixed", "Fixed-Itemwise"]
  };
  const columns = [
    { field: 'id', headerName: 'ID', width: 90, hideable: true },
    { field: 'order', headerName: 'SEQ', width: 90 },
    { field: 'suborder', headerName: 'SUBSEQ', width: 90 },
    { field: 'name', headerName: 'Name', width: 130 },
    {
      field: 'accountId', headerName: 'A/C', width: 130,
      valueGetter: (params) => params.row.account?.name || ''  // Handle case when account is null
    },
    { field: 'accountCategory', headerName: 'Account Category', width: 150 },
    { field: 'operation', headerName: 'Operation', width: 130 },
    { field: 'accountImpact', headerName: 'Account Impact', width: 150 },
    { field: 'accountNature', headerName: 'Account Nature', width: 150 },
    { field: 'calculationMethod', headerName: 'Calculation Method', width: 170 },
    { field: 'status', headerName: 'Status', width: 100 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      renderCell: (params) => (
        <IconButton
          color="error"
          onClick={() => handleDelete(params.row.id)}
          title="Delete"
        >
          <GridDeleteIcon />
        </IconButton>
      ),
    },
  ];
  const onSubmit = handleSubmit(async (payload) => {
    setLoading(true);
    try {
      const URL = 'automobile/tfields/';
      setLoading(true);
      let response;
      if (payload.id) {
        // Update if payload has an ID
        response = await update({
          url: `automobile/tfields/`,
          payload: sanitizeComplexObject(payload),
        });
        if (response.success && response.show) {
          enqueueSnackbar('Updated!');
        }
      } else {
        const temp = JSON.parse(JSON.stringify(payload));
        delete temp.id;
        // Create if payload does not have an ID
        response = await create({
          url: 'automobile/tfields/',
          payload: temp,
        });
        if (response.success && response.show) {
          enqueueSnackbar('Created!');
        }
      }
      setLoading(false);
      fetchInitialValues()
    } catch (error) {
      setLoading(false);
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });
  const handleCloseAccount = () => {
    quickEditAccount.onFalse();
    HandleAccountList();
  };
  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if (!loading) {
        await trigger();
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  const HandleAccountList = async () => {
    let URL = 'automobile/accounts/';
    URL += `?page=${1}&limit=${10000}&status=true&ac_groupId=10,32,35,4,16,116,91,118,70&`;
    const { data } = await fetcher(URL);
    setAccountList(data?.rows);
  };

  const fetchInitialValues = useCallback(async () => {
    let URL = 'automobile/tfields/';
    URL += `?page=${1}&limit=${10000}&status=true&t_type=${allValues.t_type}&`;
    const { data } = await fetcher(URL);
    if (data?.rows?.length) {
      setRows(data?.rows)
    }
  }, [allValues.t_type]);

  useEffect(() => {
    if (open) {
      HandleAccountList();
      fetchInitialValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, allValues.t_type]);
  const handleRowDoubleClick = (params) => {
    const row = params.row;
    // Set values for all fields
    reset({
      name: row.name || '',
      order: row.order || 1,
      suborder: row.suborder || 1,
      accountId: row.accountId || '',
      t_type: row.t_type || 8,
      accountCategory: row.accountCategory || '',
      operation: row.operation || '',
      ishidden: row.ishidden,
      accountImpact: row.accountImpact || 'YES',
      accountNature: row.accountNature || 'Fixed',
      isReadOnly: row.isReadOnly || 'YES',
      calculationMethod: row.calculationMethod || 'Itemwise',
      in_type: row.in_type || '',
      tax_bi_Id: row.tax_bi_Id || [],
      cd: row.cd || '1',
      id: row.id || 0,
    });


    setAccountList(pre => pre.find(x => x?.id !== row?.account?.id) ? [...pre, row?.account] : pre)
  };
  const handleDelete = async (id) => {
    try {
      const confirmDelete = window.confirm('Are you sure you want to delete this record?');
      if (!confirmDelete) return;

      setLoading(true);
      const URL = `automobile/tfields/${id}`; // Delete endpoint
      const { success, show } = await destroy({ url: URL });

      if (success) {
        enqueueSnackbar('Deleted successfully!');
        // Remove deleted row from state
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error?.message || 'Failed to delete the record.', { variant: 'error' });
      console.error(error);
    }
  };

  useEffect(() => {
    console.log(allValues.t_type)
  }, [allValues])
  return (
    <Dialog
      initialState={{ density: 'compact' }}
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 1024, minHeight: 500 },
      }}
    >
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          top: 0,
          zIndex: 9,
          position: 'sticky',
          bgcolor: (theme) => alpha(theme.palette.background.default, 1),
        }}
      >
        <Toolbar>
          <Typography variant="h5" sx={{ flex: 1 }}>
            Invoice Settings
          </Typography>

          <CompanyYear />

          <IconButton
            color="inherit"
            edge="start"
            onClick={() => {
              onClose();
            }}
            tabIndex="-1"
          >
            <Iconify icon="mingcute:close-line" />
          </IconButton>
        </Toolbar>
        <Divider sx={{ borderStyle: 'dashed' }} />
      </Grid>

      <DialogContent sx={{ p: 2 }}>
        <FormProvider methods={methods}>
          <Grid container item xs={12} md={12} sx={{ p: 2 }} spacing={2}>
            <Grid item xs={12} sm={4} md={4} xl={4} xxl={2} >
              <RHFTextField name="name" defaultValue="" autoFocus label="Name"
                size='small'
                fullWidth
                required InputLabelProps={{ shrink: true }} />

            </Grid>
            <Grid item xs={12} sm={2} md={2} xl={2} xxl={2} >
              <RHFTextField name="order" defaultValue="" label="order"
                size='small'
                fullWidth
                required InputLabelProps={{ shrink: true }} />

            </Grid>
            <Grid item xs={12} sm={2} md={2} xl={2} xxl={2} >
              <RHFTextField name="suborder" defaultValue="" label="suborder"
                size='small'
                fullWidth
                required InputLabelProps={{ shrink: true }} />

            </Grid>
            <Grid item xs={12} sm={4} md={4} xl={4} xxl={2} >
              <RHFSelect
                size="small"
                fullWidth
                name="accountId"
                label="Account"
                value={allValues.accountId || ''}
                InputLabelProps={{ shrink: true }}
                PaperPropsSx={{ textTransform: 'capitalize' }}
              >
                <MenuItem key={0} value="">None</MenuItem>
                {accountList.map((option) => (
                  <MenuItem key={option?.id} value={option?.id}>
                    {option?.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            <Grid item xs={12} sm={4} md={4} xl={2} xxl={2} >
              <RHFSelect
                size="small"
                fullWidth
                value={allValues.t_type || ''}
                name="t_type"
                label="Transaction"
                InputLabelProps={{ shrink: true }}
                PaperPropsSx={{ textTransform: 'capitalize' }}
              >
                <MenuItem key={0} value="">None</MenuItem>
                {TRANSACTION_TYPEARRAY.filter(x => x.value > 7).map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            <Grid item xs={12} sm={4} md={4} xl={2} xxl={2} >
              <RHFSelect
                size="small"
                fullWidth
                value={allValues.accountCategory || ''}
                name="accountCategory"
                label="Account Category"
                InputLabelProps={{ shrink: true }}
                PaperPropsSx={{ textTransform: 'capitalize' }}
              >
                <MenuItem key={0} value="">None</MenuItem>
                {options.accountCategories.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </RHFSelect>

            </Grid>
            <Grid item xs={12} sm={4} md={4} xl={2} xxl={2} >
              <RHFMultiSelect
                sx={{ minWidth: '100%' }}
                checkbox
                size="small"
                name="tax_bi_Id"
                label="TAX TYPE"
                value={allValues.tax_bi_Id || []}
                InputLabelProps={{ shrink: true }}
                // options={[{ label: 'None', value: 0, disabled: true }, ...INVOICE_TYPEARRAY]}
                options={INVOICE_TYPEARRAY}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} xl={2} xxl={2} >

              <RHFSelect
                size="small"
                fullWidth
                value={allValues.in_type || ''}
                name="in_type"
                label="local | inter state"
                InputLabelProps={{ shrink: true }}
                PaperPropsSx={{ textTransform: 'capitalize' }}
              >
                <MenuItem key={0} value="">None</MenuItem>

                {PURCHASE_TYPE.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            <Grid item xs={12} sm={4} md={4} xl={4} xxl={4} >
              <RHFMultiSelect
                sx={{ minWidth: '100%' }}
                checkbox
                size="small"
                name="pcategoryId"
                label="CATEGORY"
                value={allValues.pcategoryId || []}
                InputLabelProps={{ shrink: true }}
                // options={[{ label: 'None', value: 0, disabled: true }, ...INVOICE_TYPEARRAY]}
                options={productcategories.map(x => ({ ...x, value: x.id, label: x.name }))}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4} xl={2} xxl={2} >
              <RHFSelect
                size="small"
                fullWidth
                value={allValues.operation || ''}
                name="operation"
                label="Operation"
                InputLabelProps={{ shrink: true }}
                PaperPropsSx={{ textTransform: 'capitalize' }}
              >
                <MenuItem key={0} value="">None</MenuItem>
                <MenuItem value="Add">Add</MenuItem>
                <MenuItem value="Subtract">Subtract</MenuItem>
              </RHFSelect>
            </Grid>
            <Grid item xs={12} sm={4} md={4} xl={2} xxl={2} >
              <RHFSelect
                size="small"
                fullWidth
                value={allValues.cd || ''}
                name="cd"
                label="CR / DB"
                InputLabelProps={{ shrink: true }}
                PaperPropsSx={{ textTransform: 'capitalize' }}
              >
                <MenuItem key={0} value="">None</MenuItem>
                <MenuItem value="1">Credit</MenuItem>
                <MenuItem value="2">Debit</MenuItem>
              </RHFSelect>
            </Grid>
            <Grid item xs={12} sm={4} md={4} xl={2} xxl={2} >
              <RHFSelect
                size="small"
                fullWidth
                value={allValues.accountImpact || ''}
                name="accountImpact"
                label="Account Impact"
                InputLabelProps={{ shrink: true }}
                PaperPropsSx={{ textTransform: 'capitalize' }}
              >
                <MenuItem key={0} value="">None</MenuItem>
                <MenuItem value="YES">YES</MenuItem>
                <MenuItem value="NO">NO</MenuItem>
                <MenuItem value="SEPARATE">SEPARATE</MenuItem>
              </RHFSelect>

            </Grid>
            <Grid item xs={12} sm={4} md={4} xl={2} xxl={2} >
              <FormControlLabel
                label="hidden"
                control={
                  <Checkbox
                    size="small"
                    name='ishidden'
                    checked={allValues.ishidden}
                    onChange={(e) => setValue('ishidden', e.target.checked)}
                  />
                }
              />
              {/* <RHFCheckbox size="small" name="ishidden" label="hidden" checked={allValues.ishidden || false} /> */}

              {/* <RHFSelect
                size="small"
                fullWidth
                value={allValues.ishidden || ''}
                name="ishidden"
                label="visiblity"
                InputLabelProps={{ shrink: true }}
                PaperPropsSx={{ textTransform: 'capitalize' }}
              >
                <MenuItem key={0} value="">None</MenuItem>
                <MenuItem value="true">YES</MenuItem>
                <MenuItem value="false">NO</MenuItem>
              </RHFSelect> */}

            </Grid>
            <Grid item xs={12} sm={4} md={4} xl={2} xxl={2} >
              <RHFSelect
                size="small"
                fullWidth
                value={allValues.accountNature || ''}
                name="accountNature"
                label="Account Nature"
                InputLabelProps={{ shrink: true }}
                PaperPropsSx={{ textTransform: 'capitalize' }}
              >
                <MenuItem key={0} value="">None</MenuItem>
                <MenuItem value="Fixed">Fixed</MenuItem>
                <MenuItem value="Variable">Variable</MenuItem>
                <MenuItem value="Affected A/C.">Affected A/C.</MenuItem>
              </RHFSelect>

            </Grid>
            <Grid item xs={12} sm={4} md={4} xl={2} xxl={2} >
              <RHFSelect
                size="small"
                fullWidth
                value={allValues.isReadOnly || ''}
                name="isReadOnly"
                label="Read Only"
                InputLabelProps={{ shrink: true }}
                PaperPropsSx={{ textTransform: 'capitalize' }}
              >
                <MenuItem key={0} value="">None</MenuItem>
                <MenuItem value="YES">YES</MenuItem>
                <MenuItem value="NO">NO</MenuItem>
              </RHFSelect>

            </Grid>
            <Grid item xs={12} sm={4} md={4} xl={4} xxl={2} >
              <RHFSelect
                size="small"
                fullWidth
                value={allValues.calculationMethod || ''}
                name="calculationMethod"
                label="Calculation Method"
                InputLabelProps={{ shrink: true }}
                PaperPropsSx={{ textTransform: 'capitalize' }}
              >
                <MenuItem key={0} value="">None</MenuItem>
                <MenuItem value="Itemwise">Itemwise</MenuItem>
                <MenuItem value="Fixed">Fixed</MenuItem>
                <MenuItem value="Fixed-Itemwise">Fixed-Itemwise</MenuItem>
              </RHFSelect>
            </Grid>
            <Grid item xs={12} sm={4} md={4} xl={2} xxl={2}  >
              <Button variant="contained" sx={{ mb: 0, mr: 4 }} onClick={async () => {
                await trigger();
                onSubmit();
              }}>
                {allValues.id ? 'Update' : 'Add'}
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={4} xl={2} xxl={2} >

              <Button variant="contained" sx={{ mb: 0 }} onClick={async () => {
                await trigger();
                reset(values);
              }}>
                Reset
              </Button>
            </Grid>


          </Grid>
        </FormProvider>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: 200,
            minHeight: 400,
            overflow: 'auto'
          }}
        >
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            onRowDoubleClick={handleRowDoubleClick}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false
                },
              },
            }}
          />
        </div>
      </DialogContent>
      <DialogActions
        sx={{
          bottom: 0,
          zIndex: 9,
          position: 'sticky',
          bgcolor: (theme) => alpha(theme.palette.background.default, 1),
        }}
      >
        {/* <LoadingButton
          variant="contained"
          onClick={async () => {
            await trigger();
            onSubmit();
          }}
        >
          ok
        </LoadingButton> */}
      </DialogActions>
      <AUserQuickEditForm
        row={accountEditList || null}
        open={quickEditAccount.value}
        onClose={handleCloseAccount}
        set={{
          fieldName,
          setValue,
          list: HandleAccountList,
        }}
      />
    </Dialog>
  );
}

InvoiceSettings.propTypes = {
  productcategories: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
