/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import sum from 'lodash/sum';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useFieldArray, Controller } from 'react-hook-form';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { DataGrid, GridCellEditStopReasons, gridPageCountSelector, GridPagination, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import { inputBaseClasses } from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import { MaterialReactTable } from 'material-react-table';
// utils
import { fCurrency } from 'src/utils/format-number';
// _mock
import { INVOICE_SERVICE_OPTIONS } from 'src/_mock';
import Paper from '@mui/material/Paper';
// components
import Iconify from 'src/components/iconify';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { alpha, Autocomplete, ListItem, Pagination, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField } from '@mui/material';
import { fetcher } from 'src/utils/axios';
import { GroupHeader, GroupItems } from 'src/_mock/constant_style';
import { useGetColours } from 'src/api/colours';
import PropTypes from 'prop-types';
import DataGridCustom from './view/invoice-grid-custom';
// ----------------------------------------------------------------------

export default function InvoiceStockDetails({ revalidate }) {
  const { control, setValue, watch, resetField } = useFormContext();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'stockdetails',
  });

  const values = watch();
  const totalRow = values.stockdetails.filter((item) => item.productId)?.length;
  const totalRate = values.stockdetails.reduce((a, b) => (a + b.rate), 0);
  const handleAdd = useCallback(() => {
    append({
      productId: null,
      acolourId: null,
      partno: '',
      chassisNo: '',
      enginNo: '',
      colourcode: '',
      acolour: null,
      keyNo: '',
      batteryNo: '',
      fscNo: '',
      rate: 0,
      product: null,
    });
  }, [append]);

  const handleRemove = useCallback((index) => {
    remove(index);
  }, [remove]);

  const { colours, coloursLoading, coloursError } = useGetColours();
  // const [rows, setRows] = useState(values.stockdetails);
  const handleStockChange = useCallback(
    async (event, index, field) => {
      console.log(field, index, event.target.value)

      if (field === 'colourcode' && event.target.value) {
        // Update the specific field
        update(index, {
          ...fields[index],
          [field]: event.target.value,
        });

        const colour = colours.find(x => x.identity === Number(event.target.value));
        if (colour) {
          update(index, {
            ...fields[index],
            [field]: event.target.value,
            acolourId: colour.id,
            acolour: colour,
          });
        }
      } else if (field === 'partno') {
        const value = field === 'partno' ? event.target.value : Number(event.target.value);
        // update(index, {
        //   ...fields[index],
        //   [field]: value,
        // });

        let productdata = {};

        if (field === 'partno' && value) {
          try {
            const URL = `automobile/products/${value}?field=partno`;
            const { data } = await fetcher(URL);
            productdata = data;
            if (data?.partno === value) {
              update(index, {
                ...fields[index],
                [field]: value,
                product: data,
                productId: data.id,
              });
              console.log({
                ...fields[index],
                [field]: value,
                product: data,
                productId: data.id,
              })
              const emptyRowCount = fields.filter(x => !x.partno).length;
              console.log(emptyRowCount)
              if (emptyRowCount === 1) handleAdd();
            }
          } catch (error) {
            console.error("Error fetching product details:", error);
          }
        } else if (!value) {
          // update(index, {
          //   productId: null,
          //   partno: '',
          //   chassisNo: '',
          //   enginNo: '',
          //   colourcode: '',
          //   acolour: null,
          //   keyNo: '',
          //   batteryNo: '',
          //   fscNo: '',
          //   rate: 0,
          //   product: null,
          // });
        }

        // Perform calculations for all fields, including when field is 'partno'
        const updatedDetails = fields.map((item, idx) => {
          const { qty, mrp, IGST, dprice, productId } = item;
          const currentQty = field === 'qty' && idx === index ? value : qty;
          const currentMrp = field === 'mrp' && idx === index ? value : mrp;
          const currentDprice = field === 'dprice' && idx === index ? value : dprice;
          const currentIGST = field === 'partno' && idx === index ? productdata?.IGST || 0 : IGST;

          const denominator = 100 + currentIGST;
          const gstAmount = ((currentQty * currentMrp - currentDprice) / denominator) * currentIGST;
          const netRate = (currentQty * currentMrp - currentDprice) - gstAmount;
          const amount = netRate + gstAmount;

          return {
            ...item,
            qty: currentQty ? parseFloat(currentQty.toFixed(2)) : 0,
            mrp: currentMrp ? parseFloat(currentMrp.toFixed(2)) : 0,
            dprice: currentDprice ? parseFloat(currentDprice.toFixed(2)) : 0,
            gstAmount: gstAmount ? gstAmount?.toFixed(2) : 0,
            netRate: netRate ? netRate?.toFixed(2) : 0,
            amount: amount ? amount?.toFixed(2) : 0,
          };
        });
      } else {
        update(index, {
          ...fields[index],
          [field]: event.target.value,
        });
      }

      revalidate();
    },
    [colours, fields, handleAdd, revalidate, update]
  );

  const [rows, setRows] = useState([
    {
      id: 1,
      partno: '',
      productName: '',
      chassisNo: '',
      enginNo: '',
      colourcode: '',
      acolourName: '',
      rate: 0,
      keyNo: '',
      batteryNo: '',
      fscNo: '',
    },
  ]);

  useEffect(() => {
    // if (JSON.stringify(values.stockdetails) !== JSON.stringify(rows))
    setRows(values.stockdetails.map((x, index) => ({ ...x, id: index + 1 })))
  }, [values.stockdetails])

  // const handleStockChange = useCallback(
  //   async (event, rowId, field) => {
  //     const value = event.target.value;
  //     setRows((prevRows) =>
  //       prevRows.map((row) =>
  //         row.id === rowId
  //           ? {
  //             ...row,
  //             [field]: value,
  //           }
  //           : row
  //       )
  //     );

  //     // Specific logic for partno or colourcode
  //     if (field === 'partno' && value) {
  //       try {
  //         const URL = `automobile/products/${value}?field=partno`;
  //         const { data } = await fetcher(URL);

  //         setRows((prevRows) => {
  //           // Update the existing row
  //           const updatedRows = prevRows.map((row) =>
  //             row.id === rowId
  //               ? {
  //                 ...row,
  //                 productName: data.name || '',
  //                 product: data,
  //                 productId: data.id,
  //               }
  //               : row
  //           );

  //           // Check if all rows have a non-empty 'partno'
  //           const emptyPartnoCount = updatedRows.filter((x) => !x.partno).length;

  //           // If all rows have 'partno', add a new empty row
  //           if (emptyPartnoCount === 0) {
  //             updatedRows.push({
  //               id: updatedRows.length + 1,
  //               partno: '',
  //               productName: '',
  //               chassisNo: '',
  //               enginNo: '',
  //               colourcode: '',
  //               acolourName: '',
  //               rate: 0,
  //               keyNo: '',
  //               batteryNo: '',
  //               fscNo: '',
  //             });
  //           }

  //           return updatedRows;
  //         });
  //       } catch (error) {
  //         console.error('Error fetching product details:', error);
  //       }
  //     }

  //     if (field === 'colourcode' && value) {
  //       const colour = colours.find((c) => c.identity === Number(value));
  //       if (colour) {
  //         setRows((prevRows) =>
  //           prevRows.map((row) =>
  //             row.id === rowId
  //               ? {
  //                 ...row,
  //                 acolour: colour,
  //               }
  //               : row
  //           )
  //         );
  //       }
  //     }

  //   },
  //   [colours]
  // );

  const handleAddRow = () => {
    setRows((prevRows) => [
      ...prevRows,
      {
        id: prevRows.length + 1,
        partno: '',
        productName: '',
        chassisNo: '',
        enginNo: '',
        colourcode: '',
        acolourName: '',
        rate: 0,
        keyNo: '',
        batteryNo: '',
        fscNo: '',
      },
    ]);
  };

  const handleRowUpdate = (newRow) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === newRow.id ? newRow : row))
    );

    return newRow;
  };
  useEffect(() => {
    // console.log('invoice stock details', rows)
    const rowslist = rows.filter(x => x.productId);
    if (rowslist.length)
      revalidate(rowslist);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows])


  const columnsmuidatagrid = [
    {
      field: 'partno',
      headerName: 'Code',
      width: 40,
      editable: true,
    },
    {
      field: 'productName',
      headerName: 'Product Name',
      width: 190,
      editable: false,
      valueGetter: (params) => params.row?.product?.name || '',
    },
    {
      field: 'chassisNo',
      headerName: 'Chassis No',
      width: 140,
      editable: true,
    },
    {
      field: 'enginNo',
      headerName: 'Engine No',
      width: 130,
      editable: true
    },
    {
      field: 'colourcode',
      headerName: 'Colour Code',
      width: 50,
      editable: true
    },
    {
      field: 'acolourName',
      headerName: 'Colour',
      width: 140,
      editable: false,
      valueGetter: (params) => params.row?.acolour?.name || '',
    },
    {
      field: 'rate',
      headerName: 'Rate',
      width: 80,
      editable: true,
      footer: (rows) => {
        const total = rows.reduce((sum, row) => sum + (parseFloat(row.rate) || 0), 0);
        return `Total: ${total.toFixed(2)}`;
      },
    },
    {
      field: 'keyNo',
      headerName: 'Key No',
      width: 50,
      editable: true,
    },
    {
      field: 'batteryNo',
      headerName: 'Battery No',
      width: 100,
      editable: true,
    },
    {
      field: 'fscNo',
      headerName: 'FSC No',
      width: 30,
      editable: true,
    },
  ];

  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 5 });

  const handlePageChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };
  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomFooter = ({ rows }) => {
    const totalRate = rows.reduce((sum, row) => sum + (parseFloat(row.rate) || 0), 0);
    const totalRow = rows.filter(x => x.productId)?.length;

    return (
      <Stack
        spacing={3}
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="space-between"
      >
        {/* <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
          <div style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold' }}>
            Total Entry: {totalRow}
          </div>
          <div style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold' }}>
            Total Rate: {totalRate.toFixed(2)}
          </div>
        </div> */}
        <Pagination
          count={Math.ceil(rows.length / paginationModel.pageSize)}
          page={paginationModel.page + 1}
          onChange={handlePageChange}
          style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}
        />
      </Stack>

    );
  };
  // eslint-disable-next-line react/no-unstable-nested-components
  const Pagination = ({ page, onPageChange, className }) => {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event, newPage - 1);
        }}
      />
    );
  }
  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomPagination = (props) => <Stack
    spacing={3}
    direction={{ xs: 'column', md: 'row' }}
    alignItems="center"
    justifyContent="space-between"
  >
    {/* <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
      <div style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold' }}>
        Total Entry: {totalRow}
      </div>
      <div style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold' }}>
        Total Rate: {totalRate.toFixed(2)}
      </div>
    </div> */}
    <GridPagination ActionsComponent={Pagination} {...props} />
  </Stack>


  const transformedData = useMemo(() => fields.map((row, index) => ({
    ...row,
    id: row.id || index + 1, // Ensure unique IDs
  })), [fields]);

  const memoizedColumns = useMemo(() => [
    {
      accessorKey: 'partno',
      header: 'Part No',
      size: 150,
      enableEditing: true, // Editable field
      muiEditTextFieldProps: ({ cell }) => ({
        type: 'number',
        onBlur: (event) => {
          const newValue = event.target.value; // Get the current value from the event
          const oldValue = cell.getValue(); // Get the previous value from the cell
          if (newValue !== oldValue) { // Check if the value has changed
            console.log("Value changed from", oldValue, "to", newValue);
            handleStockChange(event, cell.row.index, 'partno'); // Call handleStockChange
          } else {
            console.log("No change in value, skipping handleStockChange");
          }
        }
      }),
      Footer: ({ table }) => {
        // Calculate the total for the 'rate' column
        const total = table
          .getFilteredRowModel()
          .rows.filter(x => x.original.partno).length;

        return <span>Total Entry: {total}</span>;
      },
    },
    {
      accessorKey: 'productName',
      header: 'Product Name',
      size: 200,
      enableEditing: false, // Editable field
      Cell: ({ row }) => row.original?.product?.name || '', // Dynamically get the product name

    },
    {
      accessorKey: 'chassisNo',
      header: 'Chassis No',
      size: 150,
      enableEditing: true,
      muiEditTextFieldProps: ({ cell }) => ({
        type: 'text',
        onBlur: (event) => {
          const newValue = event.target.value; // Get the current value from the event
          const oldValue = cell.getValue(); // Get the previous value from the cell
          if (newValue !== oldValue) { // Check if the value has changed
            console.log("Value changed from", oldValue, "to", newValue);
            handleStockChange(event, cell.row.index, 'chassisNo'); // Call handleStockChange
          } else {
            console.log("No change in value, skipping handleStockChange");
          }
        }
      }),
    },
    {
      accessorKey: 'enginNo',
      header: 'Engine No',
      size: 150,
      enableEditing: true,
      muiEditTextFieldProps: ({ cell }) => ({
        type: 'text',
        onBlur: (event) => {
          const newValue = event.target.value; // Get the current value from the event
          const oldValue = cell.getValue(); // Get the previous value from the cell
          if (newValue !== oldValue) { // Check if the value has changed
            console.log("Value changed from", oldValue, "to", newValue);
            handleStockChange(event, cell.row.index, 'enginNo'); // Call handleStockChange
          } else {
            console.log("No change in value, skipping handleStockChange");
          }
        }
      }),
    },
    {
      accessorKey: 'colourcode',
      header: 'Colour Code',
      size: 150,
      enableEditing: true,
      muiEditTextFieldProps: ({ cell }) => ({
        type: 'number',
        onBlur: (event) => {
          console.log(event, cell.row.index)
          handleStockChange(event, cell.row.index, 'colourcode') // Handle onBlur for this column
        }
      }),
    },
    {
      accessorKey: 'acolourName',
      header: 'Colour',
      size: 200,
      enableEditing: false,
      Cell: ({ row }) => row.original?.acolour?.name || '', // Dynamically get the colour name
    },
    {
      accessorKey: 'rate',
      header: 'Rate',
      size: 100,
      enableEditing: true,
      aggregationFn: 'sum',
      muiEditTextFieldProps: ({ cell }) => ({
        type: 'number',
        onBlur: (event) => {
          const newValue = event.target.value; // Get the current value from the event
          const oldValue = cell.getValue(); // Get the previous value from the cell
          if (newValue !== oldValue) { // Check if the value has changed
            console.log("Value changed from", oldValue, "to", newValue);
            handleStockChange(event, cell.row.index, 'rate'); // Call handleStockChange
          } else {
            console.log("No change in value, skipping handleStockChange");
          }
        }
      }),
      Footer: ({ table }) => {
        // Calculate the total for the 'rate' column
        const total = table
          .getFilteredRowModel()
          .rows.reduce((sum, row) => sum + (parseFloat(row.original.rate) || 0), 0);

        return <span>{total.toFixed(2)}</span>;
      },
    },
    {
      accessorKey: 'keyNo',
      header: 'Key No',
      size: 100,
      enableEditing: true,
      muiEditTextFieldProps: ({ cell }) => ({
        type: 'text',
        onBlur: (event) => {
          const newValue = event.target.value; // Get the current value from the event
          const oldValue = cell.getValue(); // Get the previous value from the cell
          if (newValue !== oldValue) { // Check if the value has changed
            console.log("Value changed from", oldValue, "to", newValue);
            handleStockChange(event, cell.row.index, 'keyNo'); // Call handleStockChange
          } else {
            console.log("No change in value, skipping handleStockChange");
          }
        }
      }),
    },
    {
      accessorKey: 'batteryNo',
      header: 'Battery No',
      size: 100,
      enableEditing: true,
      muiEditTextFieldProps: ({ cell }) => ({
        type: 'text',
        onBlur: (event) => {
          const newValue = event.target.value; // Get the current value from the event
          const oldValue = cell.getValue(); // Get the previous value from the cell
          if (newValue !== oldValue) { // Check if the value has changed
            console.log("Value changed from", oldValue, "to", newValue);
            handleStockChange(event, cell.row.index, 'batteryNo'); // Call handleStockChange
          } else {
            console.log("No change in value, skipping handleStockChange");
          }
        }
      }),
    },
    {
      accessorKey: 'fscNo',
      header: 'FSC No',
      size: 100,
      enableEditing: true,
      muiEditTextFieldProps: ({ cell }) => ({
        type: 'text',
        onBlur: (event) => {
          const newValue = event.target.value; // Get the current value from the event
          const oldValue = cell.getValue(); // Get the previous value from the cell
          if (newValue !== oldValue) { // Check if the value has changed
            console.log("Value changed from", oldValue, "to", newValue);
            handleStockChange(event, cell.row.index, 'fscNo'); // Call handleStockChange
          } else {
            console.log("No change in value, skipping handleStockChange");
          }
        }
      }),
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], []);

  return (
    <Box sx={{ pt: 0 }}>
      {/* <DataGrid
        rowHeight={20}
        pageSize={5}
        columnHeaderHeight={20}
        state={{
          keyboard: {
            cell: null,
            columnHeader: null,
            isMultipleKeyPressed: false,
          }
        }}
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            fontSize: '12px', // Header font size
          },
          '& .MuiDataGrid-cell': {
            fontSize: '11px', // Cell font size
          },
          // Custom styles for pagination
          '& .MuiDataGrid-footerContainer': {
            height: 20, // Set pagination height
            minHeight: 20
          },
          '& .MuiTablePagination-root': {
            fontSize: '12px', // Set pagination font size
            margin: '0px'
          },
          // Adjust font size for dropdown and pagination label
          '& .MuiTablePagination-selectLabel, & .MuiTablePagination-input, & .MuiTablePagination-displayedRows, & .MuiTablePagination-actions': {
            fontSize: '12px', // Font size for select label and input
            margin: '0px'

          },
        }}
        hideFooterSelectedRowCount
        rows={rows}
        columns={columnsmuidatagrid}
        editMode="cell"
        processRowUpdate={handleRowUpdate}
        onCellEditStop={(params, event) => {
          const { id, field } = params;
          if (event?.target?.value) {
            handleStockChange(event, id, field);
          }
        }}
        initialState={{ density: 'compact', pagination: { paginationModel: { page: 1, pageSize: 5 } } }}
        pageSizeOptions={[5, 10, 25, 100]}
      /> */}

      {/* <Controller
        name="stockdetails"
        control={control}
        render={({ field }) => (
          <DataGridCustom
            columns={columnsmuidatagrid}
            data={field.value.map((row, index) => ({
              ...row,
              id: row.id || index + 1, // Ensure each row has a unique id
            }))}

            onCellEditStop={(params, event) => {
              if (event.target.value && params.value !== event.target.value) {
                handleStockChange(event, params.row.id - 1, params.field);
              }
            }}
          />
        )}
      /> */}
      <Controller
        name="stockdetails"
        control={control}
        render={({ field }) => (
          <MaterialReactTable
            columns={memoizedColumns}
            data={transformedData}
            defaultColumn={{
              muiTableBodyCellProps: {
                sx: {
                  height: '10px'
                }
              }
            }}

            enableColumnFooter
            enableStickyFooter
            enableStickyHeader
            enableEditing // Enable inline editing
            editDisplayMode='table' // Inline cell editing mode
            enableColumnActions={false} // Disable column actions globally
            enableColumnFilters={false} // Disable column filters globally
            enableSorting={false} // Disable sorting globally
            enablePagination // Optional: Disable pagination if not needed
            enableRowSelection={false} // Optional: Disable row selection if not needed
            enableRowNumbers // Optionally display row numbers
            initialState={{
              pagination: {
                pageIndex: 0,
                pageSize: 5
              },
              density: 'compact', // Compact table density
            }}

          />
        )}
      />

      {/* <TableContainer
        component={Paper}
        sx={{
          overflow: 'auto',
          height: '25vh',
          width: '100%',
          margin: 'auto',
        }}
      >
        <Table
          stickyHeader
          aria-label="simple table"
          sx={{
            '& .MuiTableCell-sizeMedium': {
              padding: '0px !important',
              paddingLeft: '2px !important',
            },
            '& fieldset': { borderRadius: '0px', border: 'none' },
            '& .MuiInputBase-input': { padding: '0px !important', border: 'none', fontSize: '12px !important' },
            '& .MuiOutlinedInput-root': { padding: '0px !important' },
            '& .autoComplete>div': { padding: '0px !important' },
            '& .MuiTableCell-root': {
              border: '0.01px solid #e9ecee',
              fontSize: '12px !important'
            },
            '& .MuiTableCell-head': {
              padding: '2px !important',
              borderRight: '1px solid #d0d1d2 !important',
            },
            '& .MuiTableCell-footer': {
              padding: '2px !important',
            },
            '& .MuiTableCell-head:last-child': {
              borderRight: '0px !important',
            },
            '&:last-child td': {
              borderColor: '#e9ecee !important',
            },
            '& td th': {
              fontSize: '1px !important'
            }
          }}
        >
          <TableHead sx={{ position: 'sticky', zIndex: '11111' }}>
            <TableRow>
              <TableCell sx={{ width: 20, textAlign: 'center' }}>Sr.</TableCell>
              <TableCell sx={{ width: 40, textAlign: 'center' }}>Code</TableCell>
              <TableCell sx={{ width: 200, textAlign: 'center' }}>
                Product Name
              </TableCell>
              <TableCell sx={{ width: 100, textAlign: 'center' }}>Chassis No</TableCell>
              <TableCell sx={{ width: 100, textAlign: 'center' }}>Engine No</TableCell>
              <TableCell sx={{ width: 60, textAlign: 'center' }}>C. Code</TableCell>
              <TableCell sx={{ width: 150, textAlign: 'left' }}>Colour</TableCell>
              <TableCell sx={{ width: 60, textAlign: 'center' }}>Rate</TableCell>
              <TableCell sx={{ width: 60, textAlign: 'center' }}>Key No</TableCell>
              <TableCell sx={{ width: 60, textAlign: 'center' }}>Bat. No</TableCell>
              <TableCell sx={{ width: 60, textAlign: 'center' }}>fscNo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((xdata, index) => (
              <TableRow
                key={xdata.id + index}
                sx={{
                  textAlign: 'center',
                  position: 'sticky',
                  left: 0,
                  backgroundColor:
                    values.stockdetails[index]?.t_type === 8 &&
                    !xdata?.instock &&
                    xdata?.instock !== null &&
                    '#ffbfbf',
                }}
              >
                <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                <TableCell>
                  <RHFTextField
                    name={`stockdetails[${index}].partno`}
                    size="small"
                    fullWidth
                    variant="outlined"
                    onBlur={(event) => handleStockChange(event, index, 'partno')}
                  />
                </TableCell>
                <TableCell>
                  {values.stockdetails[index]?.product?.name || ''}
                </TableCell>
                <TableCell sx={{ textAlign: 'left' }}>
                  <RHFTextField
                    name={`stockdetails[${index}].chassisNo`}
                    size="small"
                    fullWidth
                    variant="outlined"
                    type="text"
                  />
                </TableCell>
                <TableCell sx={{ textAlign: 'left' }}>
                  <RHFTextField
                    name={`stockdetails[${index}].enginNo`}
                    size="small"
                    fullWidth
                    variant="outlined"
                    type="text"
                  />
                </TableCell>
                <TableCell sx={{ textAlign: 'left' }}>
                  <RHFTextField
                    name={`stockdetails[${index}].colourcode`}
                    size="small"
                    fullWidth
                    variant="outlined"
                    type="number"
                    onChange={(event) => handleStockChange(event, index, 'colourcode')}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: 'left' }}>
                  {values.stockdetails[index]?.acolour?.name || ''}
                </TableCell>
                <TableCell sx={{ textAlign: 'left' }}>
                  <RHFTextField
                    name={`stockdetails[${index}].rate`}
                    size="small"
                    fullWidth
                    variant="outlined"
                    type="number"
                    onChange={(event) => handleStockChange(event, index, 'rate')}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: 'left' }}>
                  <RHFTextField
                    name={`stockdetails[${index}].keyNo`}
                    size="small"
                    fullWidth
                    variant="outlined"
                    type="text"
                  />
                </TableCell>

                <TableCell sx={{ textAlign: 'left' }}>
                  <RHFTextField
                    name={`stockdetails[${index}].batteryNo`}
                    size="small"
                    fullWidth
                    variant="outlined"
                    type="text"
                  />
                </TableCell>
                <TableCell sx={{ textAlign: 'left' }}>
                  <RHFTextField
                    name={`stockdetails[${index}].fscNo`}
                    size="small"
                    fullWidth
                    variant="outlined"
                    type="text"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter sx={{
            bottom: '0',
            position: 'sticky',
            zIndex: '11111',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}>
            <TableRow>
              <TableCell colSpan={7} sx={{ width: 20, textAlign: 'center' }} >
                Total Entry : {totalRow}
              </TableCell>
              <TableCell sx={{ width: 60, textAlign: 'center' }} >
                {totalRate}
              </TableCell>
              <TableCell sx={{ width: 60, textAlign: 'center' }} />
              <TableCell sx={{ width: 60, textAlign: 'center' }} />
              <TableCell sx={{ width: 70, textAlign: 'center' }} />
            </TableRow>
          </TableFooter>
        </Table>
       
      </TableContainer> */}

      {/* <Divider sx={{ my: 3, borderStyle: 'dashed' }} />

      <Stack
        spacing={3}
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-end', md: 'center' }}
      >
        <Button
          size="small"
          color="primary"
          startIcon={<Iconify icon="mingcute:add-line" />}
          onClick={handleAdd}
          sx={{ flexShrink: 0 }}
        >
          Add Item
        </Button>

      </Stack> */}
    </Box>
  );
}

InvoiceStockDetails.propTypes = {
  revalidate: PropTypes.func
};