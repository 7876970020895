import numeral from 'numeral';

// ----------------------------------------------------------------------
// Define Indian number format locale
numeral.register('locale', 'en-in', {
  delimiters: {
    thousands: ',', // Use commas for thousands
    lakh: ',', // Use commas for thousands
    decimal: '.'    // Use a dot for decimals
  },
  abbreviations: {
    thousand: 'K',
    lakh: 'L',
    crore: 'Cr'
  },
  ordinal() {
    return '';
  },
  currency: {
    symbol: '₹'
  },

});
export function fNumber(number) {
  return numeral(number).format();
}
export const formatCurrency = (value) => new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
}).format(value);

export function fCurrency(number) {
  const format = number ? numeral(number).format('₹0,0.00') : '';

  return result(format, '.00');
}

export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}
