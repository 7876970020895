/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
// components
import { RHFCheckbox, RHFSelect, RHFTextField } from 'src/components/hook-form';
import ComponentBlock from 'src/sections/_examples/component-block';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import { fMoney } from 'src/_mock/constant_funcation';
import CardHeader from '@mui/material/CardHeader';
import { isNaN } from 'lodash';
import CardContent from '@mui/material/CardContent';
import { Autocomplete, Box, Button, CircularProgress, ListItem, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import { INVOICE_TYPEARRAY, ITCLIST, PURCHASE_TYPE, TRANSACTION_TYPEARRAY } from '../../../_mock/constant';

// ----------------------------------------------------------------------

const StyledBlockContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(5),
  border: `solid 1px ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius * 1.5,
  backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 100 : 800],
}));
export default function InvoiceNewEditStatusDate({ partyInputValue, setPartyInputValue, party, partyOptions, partyCode, partyLoading, setPartyCode, commonfield, getCommonField, goDownData, productcategories, handleAttach }) {
  const theme = useTheme();
  const { control, watch } = useFormContext();
  const values = watch();


  return (
    <Stack
      spacing={3}
      direction={{ xs: 'column' }}
      sx={{ p: 2, }}
    >
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} rowSpacing={2} columnSpacing={1} id="entertotab">
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ m: 0 }}>
            Invoice Details
          </Typography>
        </Grid>
        <Grid item xs={12}><Divider sx={{ borderStyle: 'dashed' }} /></Grid>
        <Grid item xs={6} sm={3} md={2.5} lg={6} xl={4} xxl={1}>
          <RHFSelect
            size="small"
            fullWidth
            autoFocus
            name="t_type"
            label="Transaction"
            InputLabelProps={{ shrink: true }}
            PaperPropsSx={{ textTransform: 'capitalize' }}
          >
            <MenuItem key={0} value="">None</MenuItem>

            {TRANSACTION_TYPEARRAY.filter(x => x.value > 7).map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </RHFSelect>
        </Grid>
        <Grid item xs={6} sm={3} md={2.5} lg={6} xl={4} xxl={1}>
          <RHFSelect
            size="small"
            fullWidth
            name="pcategoryId"
            label="Category"
            InputLabelProps={{ shrink: true }}
            PaperPropsSx={{ textTransform: 'capitalize' }}
          >
            <MenuItem key={0} value="">None</MenuItem>

            {productcategories.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </RHFSelect>
        </Grid>


        {
          INVOICE_TYPEARRAY.filter(x => x.t_type.includes(values.t_type)).length !== 0 && <Grid item xs={6} sm={3} md={2} lg={6} xl={4} xxl={1}>
            <RHFSelect
              size="small"
              fullWidth
              name="tax_bi_Id"
              label="TYPE"
              value={values.tax_bi_Id}
              InputLabelProps={{ shrink: true }}
              PaperPropsSx={{ textTransform: 'capitalize' }}
              disabled
            >
              {INVOICE_TYPEARRAY.filter(x => x.t_type.includes(values.t_type)).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </RHFSelect>
          </Grid>
        }
        <Grid item xs={6} sm={3} md={2} lg={6} xl={4} xxl={1}>
          <RHFSelect
            size="small"
            fullWidth
            name="in_type"
            label="local | inter state"
            value={values.in_type}
            InputLabelProps={{ shrink: true }}
            PaperPropsSx={{ textTransform: 'capitalize' }}
            disabled
          >
            <MenuItem key={0} value="">None</MenuItem>

            {PURCHASE_TYPE.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </RHFSelect>
        </Grid>

        {
          getCommonField('identity', values)?.show && <Grid item xs={6} sm={3} md={2.5} lg={6} xl={4} xxl={1}>
            <RHFTextField
              size="small"
              name={getCommonField('identity', values)?.label}
              label={commonfield?.[values.t_type]?.[values.pcategoryId]?.identity?.label || ''}
              value={values.identity}
            />
          </Grid>
        }
        {
          getCommonField('dt', values)?.show && <Grid item xs={6} sm={3} md={2.5} lg={6} xl={4} xxl={1}>
            <Controller
              name="dt"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  defaultValue={new Date(field.value)}
                  timezone="Asia/Kolkata"
                  label={getCommonField('dt', values)?.label || ''}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  disabled={!values.tax_bi_Id}
                  format="dd/MM/yyyy"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                      size: 'small',
                    },
                  }}
                />
              )}
            />
          </Grid>
        }
        {
          getCommonField('bill_no', values)?.show && <Grid item xs={6} sm={3} md={2.5} lg={6} xl={4} xxl={1}>
            <RHFTextField
              size="small"
              name="bill_no"
              InputLabelProps={{ shrink: !!values.bill_no }}
              label={getCommonField('bill_no', values)?.label || ''}
              value={values.bill_no}
            />
          </Grid>
        }
        {
          getCommonField('b_date', values)?.show && <Grid item xs={6} sm={3} md={2} lg={6} xl={4} xxl={1}>
            <Controller
              name="b_date"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  defaultValue={new Date(field.value)}
                  timezone="Asia/Kolkata"
                  label={getCommonField('b_date', values)?.label || ''}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  format="dd/MM/yyyy"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                      size: 'small',
                    },
                  }}
                />
              )}
            />
          </Grid>
        }
        {
          getCommonField('partyNo', values)?.show && <Grid item xs={6} sm={3} md={2} lg={6} xl={4} xxl={1}>

            <Autocomplete
              autoHighlight
              fullWidth
              autoComplete
              freeSolo
              disableClearable
              inputValue={partyInputValue}
              options={partyOptions.map((option) => `${option.identity} - ${option.name}`)}
              getOptionLabel={(option) => option}
              size="small"
              onInputChange={(e, va, s) => {
                const accountno = va.split('-');
                if (e === null && accountno.length && s !== 'input')
                  setPartyCode(parseInt(va.split('-')[0], 10))
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search input"
                  onChange={(e) => {
                    setPartyInputValue(e.target.value)
                    return e;
                  }}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                    endAdornment: (
                      <>
                        {partyLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              componentsProps={{
                paper: {
                  sx: {
                    width: 600,
                  },
                },
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                    {option}
                  </ListItem>
                </Box>
              )}
            />
            {/* <RHFTextField name="partyNo" value={partyCode} label="Party No." size="small" onChange={(e) => {
              setPartyCode(e.target.value)
              return e;
            }} /> */}
          </Grid>
        }
        {
          getCommonField('egforitc', values)?.show && <Grid item xs={6} sm={3} md={2} lg={6} xl={8} xxl={1}>
            <RHFSelect
              size="small"
              fullWidth
              name="egforitc"
              label="ITC"
              value={values.egforitc}
              InputLabelProps={{ shrink: true }}
              PaperPropsSx={{ textTransform: 'capitalize' }}
            >
              <MenuItem key={0} value="">None</MenuItem>
              {ITCLIST.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </RHFSelect>
          </Grid>
        }
        {party?.name && <Grid container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} rowSpacing={2} columnSpacing={1} id="entertotab">
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ m: 0 }}>
              Party Details
            </Typography></Grid>
          <Grid item xs={12}><Divider sx={{ borderStyle: 'dashed' }} /></Grid>
          <Grid item xs={12}>
            <table style={{ fontSize: '14px' }}>
              <tr>
                <th align='left'>Party Name</th>
                <td> : </td>
                <td>{party?.name || ''}</td>
              </tr>
              <tr>
                <th align='left'>BAL</th>
                <td> : </td>
                <td>{isNaN(parseFloat(party?.closingBalance)) ? 0 : parseFloat(party?.closingBalance).toFixed(2) || ''} {party?.bal_type === 1 ? 'CR' : 'DB'}</td>
              </tr>
              <tr>
                <th align='left'>GST NO</th>
                <td> : </td>
                <td>{party?.gst_no || ''}</td>
              </tr>
              {/* <tr>
              <th align='left'>PinCode</th>
              <td> : </td>
              <td>{party?.pincode || ''}</td>
            </tr>
            <tr>
              <th align='left'>Mobile</th>
              <td> : </td>
              <td>{party?.mobile || ''}</td>
            </tr>
            <tr>
              <th align='left'>Address</th>
              <td> : </td>
              <td>{party?.address || ''}</td>
            </tr> */}
              <tr>
                <th align='left'>State</th>
                <td> : </td>
                <td>{party?.state?.name || ''}</td>
              </tr>
            </table>
          </Grid>
        </Grid>

        }


        {
          getCommonField('gst_no', values)?.show && <Grid item xs={12} sm={12} md={4.5} lg={12} xl={12} xxl={12}>
            {values?.gst_no ? `GST IN : ${values?.gst_no}` : 'NILL'}
          </Grid>
        }


      </Grid>


      {
        getCommonField('truckNo', values)?.show && <Grid container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} rowSpacing={2} columnSpacing={1} id="entertotab">

          <Grid item xs={12}>
            <Typography variant="h6" sx={{ m: 0 }}>
              Transport Details
            </Typography></Grid>
          <Grid item xs={12}><Divider sx={{ borderStyle: 'dashed' }} /></Grid>
          {
            getCommonField('truckNo', values)?.show && <Grid item xs={6} sm={3} md={2.5} lg={6} xl={4} xxl={1}>
              <RHFTextField name="truckNo" label="Truck No." size="small" />
            </Grid>
          }
          {
            getCommonField('inTras', values)?.show && <Grid display="flex" justifyContent={{ xs: 'center', lg: 'end' }} alignItems="center" xs={6} sm={3} md={2} lg={6} xl={4} xxl={1} >
              <RHFCheckbox name="inTras" label="In Transit" size="small" disabled />
            </Grid>
          }
          {
            getCommonField('receivedDate', values)?.show && <Grid item xs={6} sm={3} md={2.5} lg={6} xl={4} xxl={1}>
              <Controller
                name="receivedDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    defaultValue={new Date(field.value)}
                    timezone="Asia/Kolkata"
                    label="Rec. Date"
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    format="dd/MM/yyyy"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                        size: 'small',
                      },
                    }}
                  />
                )}
              />
            </Grid>
          }
          {
            getCommonField('LRefNo', values)?.show && <Grid display="flex" justifyContent={{ xs: 'center', lg: 'start' }} alignItems="center" xs={6} sm={3} md={2.5} lg={6} xl={6} xxl={1}>
              <Stack direction={{ xs: 'row', sm: 'column', xl: 'row' }} sx={{ fontSize: '14px', paddingLeft: 1, }}>
                <div> {getCommonField('LRefNo', values)?.label}</div>
                <div>{values?.LRefNo}</div>
              </Stack>
            </Grid>
          }
          {
            getCommonField('godownId', values)?.show && <Grid item xs={6} sm={6} md={2.5} lg={6} xl={6} xxl={6}>
              <RHFSelect
                size="small"
                fullWidth
                name="godownId"
                label={getCommonField('godownId', values)?.label}
                InputLabelProps={{ shrink: true }}
                PaperPropsSx={{ textTransform: 'capitalize' }}
              >
                <MenuItem key={0} value="">None</MenuItem>

                {goDownData.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
          }
          {
            getCommonField('transportName', values)?.show && <Grid item xs={12} sm={12} md={4.5} lg={12} xl={12} xxl={12}>
              <RHFTextField name="transportName" label="Transport Name" size="small" />
            </Grid>
          }

        </Grid>
      }

    </Stack>
  );
}
InvoiceNewEditStatusDate.propTypes = {
  partyCode: PropTypes.number,
  goDownData: PropTypes.array,
  partyOptions: PropTypes.array,
  productcategories: PropTypes.array,
  handleAttach: PropTypes.func,
  getCommonField: PropTypes.func,
  setPartyCode: PropTypes.func,
  setPartyInputValue: PropTypes.func,
  commonfield: PropTypes.object,
  partyLoading: PropTypes.bool,
  partyInputValue: PropTypes.bool,
  party: PropTypes.object,
};