import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
//
import LogTable from 'src/layouts/_common/log/log-manage';
import { formatDate } from 'src/_mock/constant_funcation';
import ProductCategoryQuickEditForm from './quick-edit-form';

// ----------------------------------------------------------------------

export default function UserTableRow({
  tableData,
  row,
  index,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  table,
  getAll,
  permission,
}) {
  const { avariant, dealprice, roadtax, regischarge, rto, cgst, insurance, effdate, onroad, partno, product } = row;

  const [view, setView] = useState(false);

  const confirm = useBoolean();

  const quickEdit = useBoolean();
  const quickLog = useBoolean();

  const popover = usePopover();

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell>{index}</TableCell>
        <TableCell>{partno}</TableCell>
        <TableCell>{product?.name}</TableCell>
        {/* <TableCell>{avariant?.name}</TableCell> */}
        <TableCell>{dealprice}</TableCell>
        <TableCell>{roadtax}</TableCell>
        <TableCell>{rto}</TableCell>
        <TableCell>{cgst * 2}</TableCell>
        <TableCell>{insurance}</TableCell>
        <TableCell>{formatDate(effdate)}</TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="View" placement="top" arrow>
            <IconButton
              color={quickEdit.value ? 'inherit' : 'default'}
              onClick={() => {
                quickEdit.onTrue();
                setView(true);
              }}
            >
              <Iconify icon="carbon:view-filled" />
            </IconButton>
          </Tooltip>
          {permission?.u && (
            <Tooltip title="Quick Edit" placement="top" arrow>
              <IconButton
                color={quickEdit.value ? 'inherit' : 'default'}
                onClick={() => {
                  quickEdit.onTrue();
                  setView(false);
                }}
              >
                <Iconify icon="solar:pen-bold" />
              </IconButton>
            </Tooltip>
          )}
          {(permission?.u || permission?.d) && (
            <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          )}
        </TableCell>
      </TableRow>

      <ProductCategoryQuickEditForm
        tableData={tableData}
        view={view}
        row={row}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
        table={table}
        getAll={getAll}
      />

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        {permission?.d && (
          <MenuItem
            onClick={() => {
              confirm.onTrue();
              popover.onClose();
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon="solar:trash-bin-trash-bold" />
            Delete
          </MenuItem>
        )}

        {permission?.u && (
          <MenuItem
            onClick={() => {
              quickEdit.onTrue();
              popover.onClose();
            }}
          >
            <Iconify icon="solar:pen-bold" />
            Edit
          </MenuItem>
        )}

        {permission?.l && (
          <MenuItem
            onClick={() => {
              quickLog.onTrue();
              popover.onClose();
            }}
          >
            <Iconify icon="mdi:refresh" />
            Log
          </MenuItem>
        )}
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />

      {quickLog.value && (
        <LogTable
          open={quickLog.value}
          logDetails={{
            name: 'Prices Master Log',
            API: 'automobile/product/prices/',
            originalId: row?.id,
          }}
          onClose={() => {
            quickLog.onFalse();
          }}
        />
      )}
    </>
  );
}

UserTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  index: PropTypes.number,
  selected: PropTypes.bool,
  table: PropTypes.object,
  permission: PropTypes.object,
  getAll: PropTypes.func,
  tableData: PropTypes.array,
};
